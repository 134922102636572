import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import Card from "react-bootstrap/Card"

import axios from "axios";
import noimage from "../../assets/images/noimage.png";
import { getBaseUrl } from "../../config";
import { useNavigate } from "react-router-dom";

const TemplateList = () => {
  const [templateList, setTemplateList] = useState(null);
  const [showLoader, setShowLoader] = useState(null);
  const [hideInactive, setHideInactive] = useState(true);
  const navigate = useNavigate();
  const openTemplateEdit = (templateId) => {
    navigate("/create-template?templateId=" + templateId);
  };
  const openVideoInviteEdit = (templateId) => {
    navigate("/add-video-invite?templateId=" + templateId);
  };
  const openCreateTemplate = () => {
    navigate("/create-template");
  };
  const openAddFont = () => {
    navigate("/add-font");
  };
  const openAddVideoInvite = () => {
    navigate("/add-video-invite");
  };
  const updateTemplate = (templateId, active) => {
    setShowLoader(true);
    let params = {
      active: active,
    };
    axios
      .put(
        `${getBaseUrl()}api/invitationTemplates/deactivate/${templateId}`,
        params
      )
      .then((resp) => {
        setShowLoader(false);
        getTemplateList();
      })
      .catch((err) => {
        setShowLoader(false);
        console.error(err);
      });
  };

  const getTemplateList = () => {
    setShowLoader(true);
    axios
      .get(`${getBaseUrl()}api/v1/template/list?showInActive=true&version=1.1`)
      .then((resp) => {
        setShowLoader(false);
        setTemplateList(resp.data.templateList);
      })
      .catch((err) => {
        setShowLoader(false);
        console.error(err);
      });
  };

  useEffect(() => {
    getTemplateList();
  }, []);
  return (
    <React.Fragment>
      <Container>
        <Button
          style={{ margin: 20, marginLeft: 30 }}
          onClick={() => {
            openCreateTemplate();
          }}
        >
          Create a new template
        </Button>
        <Button
          style={{ margin: 20, marginLeft: 30 }}
          onClick={() => {
            openAddFont();
          }}
        >
          Add New Font
        </Button>
        <Button
          style={{ margin: 20, marginLeft: 30 }}
          onClick={() => {
            openAddVideoInvite();
          }}
        >
          Add Video Invite
        </Button>
        <Button
          style={{ margin: 20, marginLeft: 30 }}
          onClick={() => {
            setHideInactive(!hideInactive);
          }}
        >
          {hideInactive ? "See All Templates" : "See Active Templates"}
        </Button>
      </Container>
      <Container>
        {templateList &&
          templateList.map((data, index) => {
            return (
              <Col>
                <Row style={{ marginBottom: "14px", marginLeft: "10px" }}>
                  <h1>{data.categoryName}</h1>
                </Row>
                <Container>
                  <Row>
                    {data.data &&
                      data.data.map((templates, index) => {
                        let show = true;
                        if (hideInactive) {
                          if (!templates.active) {
                            show = false;
                          }
                        }
                        if (show) {
                          return (
                            <Col xs={3}>
                              <Card
                                style={{
                                  marginRight: "10px",
                                  marginTop: "10px",
                                  width: "16rem",
                                }}
                              >
                                <Card.Img
                                  style={{
                                    height: "250px",
                                    objectFit: "cover",
                                  }}
                                  variant="top"
                                  src={
                                    templates.thumbnail
                                      ? templates.thumbnail
                                      : noimage
                                  }
                                />
                                <Card.Body>
                                  <Card.Text>{templates.title}</Card.Text>
                                  <Button
                                    onClick={() => {
                                      if (data.categoryId == 3)
                                        openVideoInviteEdit(templates.id);
                                      else openTemplateEdit(templates.id);
                                    }}
                                    variant="primary"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      updateTemplate(
                                        templates.id,
                                        !templates.active
                                      );
                                    }}
                                    style={{ marginLeft: "10px" }}
                                    variant="primary"
                                  >
                                    {templates.active
                                      ? "Deactivate"
                                      : "Activate"}
                                  </Button>
                                </Card.Body>

                                {/* <img height={250} width={200} style={{ objectFit: 'cover' }} src={templates.thumbnail ? templates.thumbnail : noimage}></img> */}
                              </Card>
                            </Col>
                          );
                        }
                      })}
                  </Row>
                </Container>
              </Col>
            );
          })}
      </Container>
      {showLoader && <div className="LoaderBackDrop"></div>}
      {showLoader && (
        <div className="Loader">
          <Spinner className="spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </React.Fragment>
  );
};
export default TemplateList;
