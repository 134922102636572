import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from 'axios';
import { getBaseUrl } from "../../config"
import parsePsd from "../../psd-parser/";
import Pagination from "react-js-pagination";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Badge from "react-bootstrap/Badge"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getUrlParameter } from "../../helper"
import PreviewModal from "../Preview"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { resizeFile } from '../../helper/index';
import { useNavigate } from "react-router-dom";

const CreateTemplate = () => {
  const navigate = useNavigate();
  const openMainScreen = () => {
    navigate('/')
  }
  const templateId = getUrlParameter("templateId");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [file, setFile] = useState();
  const [buffer, setBuffer] = useState();
  const [fileName, setFileName] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [pages, setPages] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [finalizePages, setFinalizePages] = useState(false);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [showParseSpinner, setShowParseSpinner] = useState(false);

  const [isUpdateTemplate, setIsUpdateTemplate] = useState(false);

  const [templateTitle, setTemplateTitle] = useState("");
  const [templateDesignedBy, setTemplateDesignedBy] = useState("");
  const [invitationId, setInvitationId] = useState(null);

  const [templateStatus, setTemplateStatus] = useState(false);

  const [brideSelected, setBrideSelected] = useState(false);
  const [groomSelected, setGroomSelected] = useState(false);
  const [brideAndGroomSelected, setBrideAndGroomSelected] = useState(false);

  const [templateThumbnail, setTemplateThumbnail] = useState("");
  const [templateCategory, setTemplateCategory] = useState("1");
  const [templateCardType, setTemplateCardType] = useState("image");

  const [brideSelectedValue, setBrideSelectedValue] = useState("");
  const [groomSelectedValue, setGroomSelectedValue] = useState("");
  const [brideAndGroomSelectedValue, setBrideAndGroomSelectedValue] = useState("");
  const [excludeArray, setExcludeArray] = useState([]);
  const [excludeArrayString, setExcludeArrayString] = useState([]);

  const [fontsData, setFontsData] = useState(null);

  const [dateName, setDateName] = useState(null);
  const [dateValue, setDateValue] = useState(null);

  const [fontsLoaded, setFontsLoaded] = useState(null);

  const [slug, setSlug] = useState("template-name");

  const getFontsData = () => {
    axios.get(`${getBaseUrl()}api/fonts`)
      .then(resp => {
        setShowLoader(true)
        let fontsData = resp.data;
        setFontsData(fontsData);
        for (let i = 0; i < fontsData.length; i++) {
          let fontData = fontsData[i];
          let font = new FontFace(fontData.key, `url(${fontData.url})`);
          font.load().then(function (loadedFont) {
            document.fonts.add(loadedFont);
          }).catch(function (error) {
            console.log("error", error)
          }).finally(() => {
            if (i + 1 == fontsData.length) {
              setShowLoader(false)
              setFontsLoaded(true)
            }
          });
        }
        setFontsLoaded
      })
      .catch(err => {
        console.error(err);
      });
  }

  const getTemplateData = (templateId) => {
    setShowLoader(true)
    axios.get(`${getBaseUrl()}api/invitationTemplates/${templateId}`)
      .then(resp => {
        let response = resp.data;
        setTemplateTitle(response.title);
        setTemplateDesignedBy(response.designedBy ? response.designedBy : "");
        setInvitationId(response.invitationId);
        setTemplateStatus(response.active);
        setTemplateCardType("image");
        setTemplateCategory(response.categoryId ? response.categoryId : "1");
        setSlug(response.slug ? response.slug : "template-name")
        setTemplateThumbnail(response.thumbnail)
        let pageList = generatePageListData(response.invitationPages)
        setPages(pageList);
        setTotalPages(pageList.length);
        setShowLoader(false)
      })
      .catch(err => {
        setShowLoader(false)
        toast.error("err", err)
        console.error(err);
      });
  }
  const generatePageListData = (apiData) => {
    let pageList = []
    for (let i = 0; i < apiData.length; i++) {
      let pageData = apiData[i];
      let pageListData = {
        backgroundImage: {
          imagePath: pageData.data.backgroundImage
        },
        canvasDetails: pageData.data.canvasDetails,
        texts: [...pageData.data.texts, ...pageData.data.address, ...pageData.data.dates],
        defaultValues: {
          texts: [...pageData.defaultValues.texts, ...pageData.defaultValues.address, ...pageData.defaultValues.dates]
        },
        dateMaps: pageData.defaultValues.datesMap,
        pageOrder: pageData.pageNumber
      }
      // for (let i = 0; i < pageListData.texts.length; i++) {
      //   // let fontFound = false;
      //   // let currentFontName = pageListData.texts[i].textDetails.style.font.name;
      //   // for (let j = 0; j < fontsData.length; j++) {
      //   //   let font = fontsData[j]
      //   //   console.log("font", font);
      //   //   console.log("currentFontName", currentFontName);
      //   //   if (currentFontName == font.key) {
      //   //     fontFound = true;
      //   //     break
      //   //   }
      //   // }
      //   // if (!fontFound) {
      //   //pageListData.texts[i].textDetails.style.font.name = "Roboto-Regular";
      //   //}
      // }
      pageList.push(pageListData)
    }
    return pageList;
  }

  async function sendData(url, data) {
    const formData = new FormData();
    formData.append("file", data);
    const response = await fetch(url, {
      method: "POST",
      body: formData
    });
    return response;
  }
  useEffect(() => {
    getFontsData();
    if (templateId) {
      getTemplateData(templateId);
      setIsUpdateTemplate(true)
      //setFinalizePages(true)
    }
    // Update the document title using the browser API
  }, []);

  useEffect(() => {
    // Update the document title using the browser API
  }, [pages]);

  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setBuffer(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const base64ToArrayBuffer = base64 => {
    base64 = base64.replace(/^data\:([^\;]+)\;base64,/gim, "");
    var binaryString = atob(base64);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const saveFile = e => {
    setFile(e.target.files[0]);
    getBase64(e.target.files[0]);
    setFileName(e.target.files[0].name);
    toast.success(`${e.target.files[0].name} file uploaded`)
  };

  const uploadFile = async index => {
    setShowParseSpinner(true);
    let arrayBuffer = base64ToArrayBuffer(buffer);
    let res = await parsePsd(arrayBuffer);
    console.log("res", res)
    if (res) {
      let pageList;
      if (pages) {
        for (let i = 0; i < res.texts.length; i++) {
          let fontFound = false;
          let currentFontName = res.texts[i].textDetails.style.font.name;
          for (let j = 0; j < fontsData.length; j++) {
            let font = fontsData[j]
            if (currentFontName == font.key) {
              fontFound = true;
              break
            }
          }
          if (!fontFound) {
            res.texts[i].textDetails.style.font.name = fontsData[0].key;
          }
        }
        pageList = JSON.parse(JSON.stringify(pages));

        pageList[index] = res;
      }
      setShowParseSpinner(false);
      setPages(pageList);
      handleShow(true);
    } else {
      setShowParseSpinner(false);
      toast.error("Invalid PSD format")
    }
  };
  const uploadThumbail = async index => {
    let resizedFile = await resizeFile(file);
    setShowParseSpinner(true);
    let response = await sendData(
      `${getBaseUrl()}api/v1/file/uploadFile?type=image`,
      resizedFile
    );
    try {
      response = await response.json();
      setShowParseSpinner(false)
      let imagePath = response.data.data.Location;
      setTemplateThumbnail(imagePath);
    } catch (e) {
      setShowParseSpinner(false)
      toast.error("Unable to upload thumbnail, try again")
    }
  };
  const addNewDateValue = (pageIndex) => {
    let pagesData = JSON.parse(JSON.stringify(pages));

    if (!pagesData[pageIndex].dateMaps) {
      pagesData[pageIndex].dateMaps = {}
    }
    if (dateValue && dateName) {
      pagesData[pageIndex].dateMaps[dateName] = dateValue
      setPages(pagesData);
      setDateName(null)
      setDateValue(null)
    } else {
      toast.error(`Please pick a ${dateValue ? 'name' : 'date'}`)
    }

  }
  const onTextChangeHandle = (value, textIndex, pageIndex) => {
    value = value.replaceAll("\n", "<br>")
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].defaultValues.texts[textIndex] = value;
    pageData[pageIndex].texts[textIndex].valueUpdated = true;
    if (pageData[pageIndex].texts[textIndex].brideAndGroomType && pageData[pageIndex].texts[textIndex].brideAndGroomType == "brideSelected") {
      setBrideSelectedValue(value)
    }
    if (pageData[pageIndex].texts[textIndex].brideAndGroomType && pageData[pageIndex].texts[textIndex].brideAndGroomType == "groomSelected") {
      setGroomSelectedValue(value)
    }
    if (pageData[pageIndex].texts[textIndex].brideAndGroomType && pageData[pageIndex].texts[textIndex].brideAndGroomType == "brideAndGroomSelected") {
      setBrideAndGroomSelectedValue(value)
    }
    setPages(pageData);
  };
  const onDateKeyChangeHandle = (value, textIndex, pageIndex) => {
    let pagesData = JSON.parse(JSON.stringify(pages));
    if (pagesData[pageIndex].dateMaps[value]) {
      if (!pagesData[pageIndex].defaultValues.texts[textIndex]) {
        pagesData[pageIndex].defaultValues.texts[textIndex] = {
          "key": value,
          "format": "ddd"
        };
      } else {
        pagesData[pageIndex].defaultValues.texts[textIndex].key = value;
      }
    } else {
      pagesData[pageIndex].defaultValues.texts[textIndex] = {
        "key": null,
        "format": "ddd"
      };
    }
    setPages(pagesData);
  };

  const onDateFormatChangeHandle = (value, textIndex, pageIndex) => {
    let pagesData = JSON.parse(JSON.stringify(pages));
    pagesData[pageIndex].defaultValues.texts[textIndex].format = value;
    pagesData[pageIndex].texts[textIndex].valueUpdated = true;
    setPages(pagesData);
  };
  const onAddressChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    value = value.replaceAll("\n", "<br>")
    pageData[pageIndex].texts[textIndex].valueUpdated = true;
    pageData[pageIndex].defaultValues.texts[textIndex] = {
      textValue: value,
      googleMapsData: null
    };
    setPages(pageData);
  };

  const onTypeChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].type = value;
    if (value == "text") {
      pageData[pageIndex].defaultValues.texts[textIndex] = "";
    } else if (value == "date") {
      pageData[pageIndex].defaultValues.texts[textIndex] = {};
    } else if (value == "address") {
      let defaultText = ""
      if (typeof pageData[pageIndex].defaultValues.texts[textIndex] == "string") {
        defaultText = pageData[pageIndex].defaultValues.texts[textIndex]
      }
      pageData[pageIndex].defaultValues.texts[textIndex] = {
        textValue: defaultText,
        googleMapsData: null
      };
    }
    setPages(pageData);
  };

  const getBrideAndGroomSlugName = () => {
    let brideAndGroomSlugName = "";
    if (brideSelected && !brideAndGroomSelected) {
      brideAndGroomSlugName += brideSelectedValue;
    }
    if (groomSelected && !brideAndGroomSelected) {
      brideAndGroomSlugName += groomSelectedValue;
    }
    if (brideAndGroomSelected) {
      brideAndGroomSlugName += brideAndGroomSelectedValue;
    }
    if (excludeArray && excludeArray.length > 0) {
      for (let i = 0; i < excludeArray.length; i++) {
        let excludeValue = excludeArray[i]
        brideAndGroomSlugName = brideAndGroomSlugName.toLowerCase();
        brideAndGroomSlugName = brideAndGroomSlugName.replaceAll(new RegExp("\\b" + excludeValue.toLowerCase() + "\\b"), "")
      }
    }
    brideAndGroomSlugName = brideAndGroomSlugName.replaceAll(/[^a-zA-Z0-9 ]/g, "")
    brideAndGroomSlugName = brideAndGroomSlugName.replaceAll(/\s\s+/g, ' ');
    brideAndGroomSlugName = brideAndGroomSlugName.trim();
    brideAndGroomSlugName = brideAndGroomSlugName.replaceAll(" ", "-")
    return brideAndGroomSlugName;
  }

  const onBandAChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    if (value == "brideSelected") {
      if (pageData[pageIndex].texts[textIndex].brideAndGroomType == "groomSelected") {
        setGroomSelected(false);
        setGroomSelectedValue("")
      }
      setBrideSelected(true);
      setBrideSelectedValue(pageData[pageIndex].defaultValues.texts[textIndex])
      setBrideAndGroomSelectedValue("")
    }
    if (value == "groomSelected") {
      if (pageData[pageIndex].texts[textIndex].brideAndGroomType == "brideSelected") {
        setBrideSelected(false);
        setBrideSelectedValue("");
      }
      setGroomSelected(true);
      setGroomSelectedValue(pageData[pageIndex].defaultValues.texts[textIndex])
      setBrideAndGroomSelectedValue("")
    }
    if (value == "brideAndGroomSelected") {
      setBrideAndGroomSelectedValue(pageData[pageIndex].defaultValues.texts[textIndex])
      setBrideAndGroomSelected(true);
      setBrideSelected(false);
      setGroomSelected(false);
    }
    if (value == "None") {
      if (pageData[pageIndex].texts[textIndex].brideAndGroomType == "brideSelected") {
        setBrideSelected(false);
        setBrideSelectedValue("");
      }
      if (pageData[pageIndex].texts[textIndex].brideAndGroomType == "groomSelected") {
        setGroomSelected(false);
        setGroomSelectedValue("")
      }
      if (pageData[pageIndex].texts[textIndex].brideAndGroomType == "brideAndGroomSelected") {
        setBrideAndGroomSelected(false);
        setBrideAndGroomSelectedValue("")
      }
      pageData[pageIndex].texts[textIndex].brideAndGroomType = null;
      setPages(pageData);
    } else {
      pageData[pageIndex].texts[textIndex].brideAndGroomType = value;
      pageData[pageIndex].texts[textIndex].brideAndGroomExcludeArray = [];
      setPages(pageData);
    }
  };

  const onExcludeArrayChange = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    try {
      setExcludeArrayString(value)
      value = value.split(",")
      setExcludeArray(value)
      //pageData[pageIndex].texts[textIndex].brideAndGroomExcludeArray = value;
      setPages(pageData);
    } catch (e) {

    }
  };

  const onFontChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].textDetails.style.font.name = value;
    setPages(pageData);
  };

  const onFontSizeChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].textDetails.style.fontSize = value;
    setPages(pageData);
  };

  const onAndroidPaddingChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].textDetails.style.androidPadding = value;
    setPages(pageData);
  }

  const onAnimationTypeChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].animationType = value;
    setPages(pageData);
  };

  const onAnimationDelayChangeHandle = (value, textIndex, pageIndex) => {
    let pageData = JSON.parse(JSON.stringify(pages));
    pageData[pageIndex].texts[textIndex].animationDelay = value;
    setPages(pageData);
  };

  const addressInput = (pageData, textIndex, pageIndex) => {
    return (
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label size="sm">Address Value</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={5}
          value={pageData.defaultValues.texts[textIndex].textValue.replaceAll("<br>", "\n")}
          onChange={e => {
            onAddressChangeHandle(e.target.value, textIndex, pageIndex);
          }}
          size="sm"
        />
      </Form.Group>
    );
  };
  const textInput = (pageData, textIndex, pageIndex) => {
    return (
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label size="sm">
          <b>Text Value</b>
        </Form.Label>
        <Form.Control
          as="textarea"
          rows={5}
          type="text"
          value={pageData.defaultValues.texts[textIndex].replaceAll("<br>", "\n")}
          onChange={e => {
            onTextChangeHandle(e.target.value, textIndex, pageIndex);
          }}
          size="sm"
        />
      </Form.Group>
    );
  };
  const dateInput = (pageData, textIndex, pageIndex) => {
    return (
      <Col>
        {pages[pageIndex].dateMaps && Object.keys(pages[pageIndex].dateMaps).length > 0 &&
          <Row style={{ flex: 1 }}>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Date Value</Form.Label>
              <Form.Select
                // as="select"
                size="sm"
                value={pageData.defaultValues.texts[textIndex].key}
                onChange={e => {
                  onDateKeyChangeHandle(e.target.value, textIndex, pageIndex);
                }}
              >
                <option value={null}>None</option>
                {Object.keys(pages[pageIndex].dateMaps).map((key, value) => {
                  return <option value={key}>{key}</option>
                })}

              </Form.Select>
            </Form.Group>
          </Row>
        }
        {pages[pageIndex].dateMaps && Object.keys(pages[pageIndex].dateMaps).length > 0 && pageData.defaultValues.texts[textIndex].key &&
          <Row style={{ flex: 1 }}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">
                <b>Format : </b>
                {(pages[pageIndex].defaultValues.texts[textIndex].key && pages[pageIndex].defaultValues.texts[textIndex].format) ? (moment(pages[pageIndex].dateMaps[pages[pageIndex].defaultValues.texts[textIndex].key]).format(pages[pageIndex].defaultValues.texts[textIndex].format) ? moment(pages[pageIndex].dateMaps[pages[pageIndex].defaultValues.texts[textIndex].key]).format(pages[pageIndex].defaultValues.texts[textIndex].format) : "Invalid format") : "Date value or name is missing"}
                <Badge variant="secondary" style={{ cursor: '-webkit-grab', cursor: 'grab', marginLeft: '20px' }} onClick={() => {
                  window.open("https://devhints.io/moment", "_blank")
                }}>Cheat sheet</Badge>
              </Form.Label>
              <Form.Control
                rows={5}
                type="text"
                value={pageData.defaultValues.texts[textIndex].format}
                onChange={e => {
                  onDateFormatChangeHandle(e.target.value, textIndex, pageIndex);
                }}
                size="sm"
              />
            </Form.Group>
          </Row>
        }
        {!pageData.defaultValues.texts[textIndex].key &&
          <Row>
            <Col>
              <Row>
                <Form.Group
                  controlId="exampleForm.ControlInput1"
                  style={{ marginTop: "20px" }}
                >
                  <Form.Label>Date and Time</Form.Label>
                  <DatePicker
                    selected={dateValue}
                    onChange={date => {
                      setDateValue(date);
                    }}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    name="Date"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label size="sm">
                    <b>Date Value Name</b>
                  </Form.Label>
                  <Form.Control
                    rows={5}
                    type="text"
                    value={dateName}
                    onChange={e => {
                      setDateName(e.target.value);
                    }}
                    size="sm"
                  />
                </Form.Group>
              </Row>
              <Row>
                <Button onClick={() => {
                  addNewDateValue(pageIndex)
                }}>Add Value</Button>
              </Row>
            </Col>
          </Row>
        }
      </Col>
    );
  };
  const renderInputBasedOnType = (pageData, textIndex, pageIndex) => {
    if (pageData.texts[textIndex].type == "text") {
      return textInput(pageData, textIndex, pageIndex);
    } else if (pageData.texts[textIndex].type == "address") {
      return addressInput(pageData, textIndex, pageIndex);
    } else if (pageData.texts[textIndex].type == "date") {
      return dateInput(pageData, textIndex, pageIndex);
    }
  };
  const renderPageElement = (pageData, data, type, textIndex, pageIndex) => {
    return (
      <Form >
        <Row className="box" style={{ marginTop: "10px" }}>
          <Col>{renderInputBasedOnType(pageData, textIndex, pageIndex)}</Col>
          <Col>
            <Row style={{ flex: 1 }}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Type</Form.Label>
                <Form.Select
                  // as="select"
                  size="sm"
                  value={pageData.texts[textIndex].type}
                  onChange={e => {
                    onTypeChangeHandle(e.target.value, textIndex, pageIndex);
                  }}
                >
                  <option value="text">Text</option>
                  <option value="address">Address</option>
                  <option value="date">Date</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {(!((brideSelected && groomSelected) || brideAndGroomSelected) || pageData.texts[textIndex].brideAndGroomType) &&
              <Row>
                <p>{`Guest view slug: ${getBrideAndGroomSlugName()}`}</p>
              </Row>
            }
            {(!((brideSelected && groomSelected) || brideAndGroomSelected) || pageData.texts[textIndex].brideAndGroomType) &&
              <Row style={{ flex: 1 }}>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Select Bride Groom</Form.Label>
                  <Form.Select
                    // as="select"
                    size="sm"
                    value={pageData.texts[textIndex].brideAndGroomType}
                    onChange={e => {
                      onBandAChangeHandle(e.target.value, textIndex, pageIndex);
                    }}
                  >
                    <option value={null}>None</option>
                    {!brideAndGroomSelected &&
                      <option value="brideSelected">Bride</option>}
                    {!brideAndGroomSelected &&
                      <option value="groomSelected">Groom</option>}
                    {!brideSelected && !groomSelected &&
                      <option value="brideAndGroomSelected">BrideAndGroom</option>}
                  </Form.Select>
                </Form.Group>
              </Row>}
            {pageData.texts[textIndex].brideAndGroomType &&
              <Row style={{ flex: 1 }}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label size="sm">Bride&Groom exclude</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=", seperated"
                    value={excludeArrayString}
                    onChange={e => {
                      onExcludeArrayChange(
                        e.target.value,
                        textIndex,
                        pageIndex
                      );
                    }}
                    size="sm"
                  />
                </Form.Group>
              </Row>}
            <Row style={{ flex: 1 }}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Font</Form.Label>
                <Form.Select
                  // as="select"
                  size="sm"
                  value={pageData.texts[textIndex].textDetails.style.font.name}
                  onChange={e => {
                    onFontChangeHandle(e.target.value, textIndex, pageIndex);
                  }}
                >
                  {fontsData.map((fontData, index) => {
                    return (
                      <option value={fontData.key}>{fontData.key}</option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label size="sm">Font Size</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="in ms"
                  value={pageData.texts[textIndex].textDetails.style.fontSize}
                  onChange={e => {
                    onFontSizeChangeHandle(
                      e.target.value,
                      textIndex,
                      pageIndex
                    );
                  }}
                  size="sm"
                />
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label size="sm">Padding for Android</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="in ms"
                  value={pageData.texts[textIndex].textDetails.style.androidPadding ? pageData.texts[textIndex].textDetails.style.androidPadding : 0}
                  onChange={e => {
                    onAndroidPaddingChangeHandle(
                      e.target.value,
                      textIndex,
                      pageIndex
                    );
                  }}
                  size="sm"
                />
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Animation</Form.Label>
                <Form.Select
                  // as="select"
                  size="sm"
                  value={pageData.texts[textIndex].animationType}
                  onChange={e => {
                    onAnimationTypeChangeHandle(
                      e.target.value,
                      textIndex,
                      pageIndex
                    );
                  }}
                >
                  <option value={null}>None</option>
                  <option value="fadeIn">Fade In </option>
                  <option value="popIn">Pop In</option>
                  <option value="circle">Circle</option>
                  <option value="leftToRight">leftToRight</option>
                  <option value="RightToLeft">RightToLeft</option>
                </Form.Select>
              </Form.Group>
            </Row>
            {pageData.texts[textIndex].animationType && (
              <Row style={{ flex: 1 }}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label size="sm">Animation Delay</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="in ms"
                    value={pageData.texts[textIndex].animationDelay}
                    onChange={e => {
                      onAnimationDelayChangeHandle(
                        e.target.value,
                        textIndex,
                        pageIndex
                      );
                    }}
                    size="sm"
                  />
                </Form.Group>
              </Row>
            )}
          </Col>
          <Col xs={2}>
            <Row>
              {textInfoButton()}
            </Row>
            <Row style={{ marginTop: '5px' }}>
              {fontsPopoverButton()}
            </Row>
            <Row style={{ marginTop: '5px' }}>
              {animationPopoverButton()}
            </Row>
            <Row style={{ marginTop: '5px' }}>
              {androidPaddingInfoButton()}
            </Row>
          </Col>
        </Row>
      </Form>
    );
  };
  const textPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Types</Popover.Header>
      <Popover.Body>
        <h6>Text Type</h6>
        Text fields are basic text fields and can be given any value.
        <br></br>
        <h6 style={{ marginTop: '2px' }}>Date Type</h6>
        On Date fields you are required to first set a global date like weddingDate etc. and then you can select
        the date type for a field and set it's format.
        <h6 style={{ marginTop: '2px' }}>Address Type</h6>
        Address Fields are also like text fields the only difference that the user will also input a google maps location in it.
      </Popover.Body>
    </Popover>
  );
  const textInfoButton = () => (
    <OverlayTrigger trigger="click" placement="left" overlay={textPopover}>
      <Button variant="success">More Info</Button>
    </OverlayTrigger>
  );

  const fontsPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Fonts</Popover.Header>
      <Popover.Body>
        You can choose from the list of fonts that are available and if you need to add a new font you can do it from the main panel
      </Popover.Body>
    </Popover>
  );
  const fontsPopoverButton = () => (
    <OverlayTrigger trigger="click" placement="left" overlay={fontsPopover}>
      <Button variant="success">Fonts Info</Button>
    </OverlayTrigger>
  );

  const animationPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Fonts</Popover.Header>
      <Popover.Body>
        You can choose an animation from the drop down and set the delay time after you have chosen the animation, make sure the delay time is in ms.
      </Popover.Body>
    </Popover>
  );
  const animationPopoverButton = () => (
    <OverlayTrigger trigger="click" placement="left" overlay={animationPopover}>
      <Button variant="success">Anim Info</Button>
    </OverlayTrigger>
  );

  const androidPaddingPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Android Padding</Popover.Header>
      <Popover.Body>
        This Vertical Padding value is for only Android device.
      </Popover.Body>
    </Popover>
  );
  const androidPaddingInfoButton = () => (
    <OverlayTrigger trigger="click" placement="left" overlay={androidPaddingPopover}>
      <Button variant="success">Pad Info</Button>
    </OverlayTrigger>
  );

  const renderPage = (page, index) => {
    if (activePage == index + 1) {
      return (
        <Container
          id={`page_${index}`}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "-15px"
          }}
        >
          <Row>
            <Col xs={4}>
              <Form size="sm">
                <Form.Control
                  type="file"
                  id="custom-file"
                  label={fileName ? fileName : "Upload PSD"}
                  size="sm"
                  custom
                  onChange={saveFile}
                />
              </Form>
            </Col>
            <Col xs={4}>
              <Button
                size="sm"
                onClick={e => {
                  uploadFile(index);
                }}
              >
                Parse Psd
              </Button>
              <Button
                size="sm"
                style={{ marginLeft: "20px" }}
                onClick={handleShow}
              >
                Preview
              </Button>
              <Button
                size="sm"
                variant="danger"
                style={{ marginLeft: "20px" }}
                onClick={() => { onRemovePage(index) }}
              >
                Remove Page
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {page.texts &&
                page.texts.map((data, textIndex) => {
                  return renderPageElement(
                    page,
                    data,
                    "Text",
                    textIndex,
                    index
                  );
                })}
            </Col>
          </Row>
        </Container>
      );
    }
  };
  const onAddPage = () => {
    let page = {};
    let pageList;
    if (pages) {
      pageList = JSON.parse(JSON.stringify(pages));
      pageList.push(page);
    } else {
      pageList = [page];
    }
    toast.success("New Page Added")
    setPages(pageList);
    setTotalPages(pageList.length);
    setActivePage(pageList.length);
  };
  const onRemovePage = (index) => {
    let pageList;
    if (pages) {
      pageList = JSON.parse(JSON.stringify(pages));
      if (index > -1) {
        pageList.splice(index, 1);
      }
    }
    setPages(pageList);
    setTotalPages(pageList.length);
    setActivePage(pageList.length);
  };
  const createTemplate = () => {
    let body = {
      title: templateTitle,
      category_id: templateCategory,
      thumbnail: templateThumbnail,
      cardType: templateCardType,
      designedBy: templateDesignedBy,
      data: []
    };
    let pagesData = [];
    let pagesValue = [];
    let fontsList = []
    let illegalData = false;
    let illegalDataMessage = "";
    //console.log("pages",JSON.stringify(pages))
    let brideAndGroomTypeExists = false;
    let brideExists = false;
    let groomExists = false;
    let brideGroomExists = false;

    for (let i = 0; i < pages.length; i++) {
      let page = pages[i];
      let pageData = {
        pageOrder: i + 1,
        backgroundImage: page.backgroundImage.imagePath,
        canvasDetails: {
          height: page.canvasDetails.height,
          width: page.canvasDetails.width
        },
        texts: [],
        address: [],
        dates: []
      };
      let pageValue = {
        texts: [],
        address: [],
        dates: [],
        datesMap: page.dateMaps
      };

      for (let j = 0; j < page.texts.length; j++) {
        let text = page.texts[j];
        if (text.brideAndGroomType) {
          brideAndGroomTypeExists = true
          if (text.brideAndGroomType == "brideSelected") {
            brideExists = true;
          }
          if (text.brideAndGroomType == "groomSelected") {
            groomExists = true;
          }
          if (text.brideAndGroomType == "brideAndGroomSelected") {
            brideGroomExists = true;
          }
        }
        let textValue = page.defaultValues.texts[j];
        fontsList.push(text.textDetails.style.font.name)
        text.textDetails.style.fontSize = parseFloat(text.textDetails.style.fontSize)
        if (text.type == "text") {
          if (textValue) {
            pageData.texts.push(text);
            pageValue.texts.push(textValue);
          } else {
            illegalData = true;
            illegalDataMessage = "Invalid text data";
          }
        } else if (text.type == "address") {
          if (textValue.textValue) {
            pageData.address.push(text);
            pageValue.address.push(textValue);
          } else {
            illegalData = true;
            illegalDataMessage = "Invalid address data";
          }
        } else if (text.type == "date") {
          if (textValue.key) {
            pageData.dates.push(text);
            pageValue.dates.push(textValue);
          } else {
            illegalData = true;
            illegalDataMessage = "Invalid date data";
          }
        }
      }
      pagesData.push(pageData);
      pagesValue.push(pageValue);
    }
    let fontsDataMap = {}
    for (let i = 0; i < fontsData.length; i++) {
      let fontData = fontsData[i]
      fontsDataMap[fontData.key] = fontData;
    }
    for (let i = 0; i < fontsList.length; i++) {
      fontsList[i] = fontsDataMap[fontsList[i]]
    }
    if (!templateTitle || templateTitle == "" || templateTitle == " ") {
      illegalData = true;
      illegalDataMessage = "Please add template title";
    } else if (!templateCategory) {
      illegalData = true;
      illegalDataMessage = "Please add template category";
    } else if (!templateThumbnail) {
      illegalData = true;
      illegalDataMessage = "Please add template thumbnail";
    } else if (!templateCardType) {
      illegalData = true;
      illegalDataMessage = "Please add template card type";
    } else if (!slug) {
      illegalData = true;
      illegalDataMessage = "Please add template slug value";
    } else if (!(excludeArray && excludeArray.length >= 0)) {
      illegalData = true;
      illegalDataMessage = "Please add brid and groom values";
    } else if (!brideAndGroomTypeExists) {
      illegalData = true;
      illegalDataMessage = "Please add brid and groom values";
    } else if ((!brideExists || !groomExists)) {
      if (!brideExists && !groomExists && !brideGroomExists) {
        illegalData = true;
        illegalDataMessage = "Please add brid and groom values";
      }
      if (brideExists && !groomExists) {
        illegalData = true;
        illegalDataMessage = "Please add brid and groom values";
      }
      if (groomExists && !brideExists) {
        illegalData = true;
        illegalDataMessage = "Please add brid and groom values";
      }
    }

    body.data = pagesData;
    body.value = pagesValue;
    body.fontsData = fontsList;
    body.slug = slug;
    body.active = templateStatus;
    body.excludeArray = setExcludeArray;

    let requestOptions;
    console.log("JSON.stringify(body)", JSON.stringify(body))
    if (!illegalData) {
      if (isUpdateTemplate && templateId) {
        // if (body.hasOwnProperty("data")) {
        //   delete body.data
        // }
        // if (body.hasOwnProperty("fontsData")) {
        //   delete body.fontsData
        // }
        // if (body.hasOwnProperty("value")) {
        //   delete body.value
        // }
        body.invitationId = invitationId;
        requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        };
        let url = `${getBaseUrl()}api/invitationTemplates/${templateId}`;
        fetch(
          url,
          requestOptions
        )
          .then(response => response.json())
          .then(data => {
            openMainScreen();
          });
      } else {
        requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body)
        };
        fetch(
          `${getBaseUrl()}api/invitationTemplates`,
          requestOptions
        )
          .then(response => response.json())
          .then(data => {
            openMainScreen();
          });
      }
    } else {
      toast.error(illegalDataMessage)
    }

  };
  const renderTemplateFinalSteps = () => {
    return (
      <React.Fragment>
        <Row>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label size="sm">Template Title</Form.Label>
            <Form.Control
              type="text"
              value={templateTitle}
              onChange={e => {
                setTemplateTitle(e.target.value);
              }}
              size="sm"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label size="sm">Designed By</Form.Label>
            <Form.Control
              type="text"
              value={templateDesignedBy}
              onChange={e => {
                setTemplateDesignedBy(e.target.value);
              }}
              size="sm"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label size="sm">Slug</Form.Label>
            <Form.Control
              type="text"
              value={slug}
              onChange={e => {
                setSlug(e.target.value);
              }}
              size="sm"
            />
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Category</Form.Label>
            <Form.Select
              // as="select"
              size="sm"
              value={templateCategory}
              onChange={e => {
                setTemplateCategory(e.target.value);
              }}
            >
              <option value={2}>Wedding Cards</option>
              {/* <option value="address">Address</option> */}
              <option value={1}>Save The Date Cards</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Card Type</Form.Label>
            <Form.Select
              // as="select"
              size="sm"
              value={templateCardType}
              onChange={e => {
                setTemplateCardType(e.target.value);
              }}
            >
              <option value="1">Image</option>
              <option value="2">Video</option>
            </Form.Select>
          </Form.Group>
        </Row>

        <Row>
          <Col xs={4}>
            <Form size="sm">
              <Form.Control
                type="file"
                style={{ marginLeft: "-15px" }}
                id="custom-file"
                label={templateThumbnail ? "Edit thumbnail" : "Add thumbnail"}
                size="sm"
                custom
                onChange={saveFile}
              />
            </Form>
          </Col>
          <Col xs={4}>
            <Button
              size="sm"
              onClick={e => {
                uploadThumbail();
              }}
            >
              Upload
            </Button>
          </Col>
        </Row>
        <Row>
          <Button
            style={{ marginTop: "10px" }}
            size="sm"
            onClick={() => {
              createTemplate();
            }}
          >
            {isUpdateTemplate ? 'Update Template' : 'Create Template'}
          </Button>
        </Row>
      </React.Fragment>
    );
  };
  const handlePageChange = pageNumber => {
    setActivePage(pageNumber);
  };
  return (
    <React.Fragment>
      <Container>
        {fontsLoaded &&
          <Col>
            <Row>
              <h1>{isUpdateTemplate ? 'Update Template' : 'Create Template'}</h1>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    onAddPage();
                  }}
                >
                  Add Page
              </Button>
              </Col>
              <Col>
                {!finalizePages && (
                  <Button
                    onClick={() => {
                      setFinalizePages(!finalizePages);
                    }}
                  >
                    Finalize Pages
                  </Button>
                )}
                {finalizePages && (
                  <Button
                    onClick={() => {
                      setFinalizePages(!finalizePages);
                    }}
                  >
                    Edit Pages
                  </Button>
                )}
              </Col>
            </Row>
            {!finalizePages && (
              <Col>
                <Row mt="10px" style={{ marginTop: '20px' }}>
                  {pages.length > 0 && totalPages > 0 && (
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={activePage}
                      itemsCountPerPage={1}
                      totalItemsCount={totalPages}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange}
                    />
                  )}
                </Row>
                <Row>
                  {pages &&
                    pages.map((page, index) => {
                      return renderPage(page, index);
                    })}
                </Row>
              </Col>
            )}
            {finalizePages && <Col>{renderTemplateFinalSteps()}</Col>}
          </Col>
        }
      </Container>
      <PreviewModal
        pageData={pages[activePage - 1] ? pages[activePage - 1] : null}
        updatePageData={(pageData, log) => {
          if (pages[activePage - 1]) {
            console.log("123123", log)
            let pageList = JSON.parse(JSON.stringify(pages));
            let currentPage = pageList[activePage - 1];
            currentPage = pageData;
            pageList[activePage - 1] = JSON.parse(JSON.stringify(currentPage))
            setPages(pageList)
          }
        }}
        show={show}
        handleClose={handleClose}
      />
      {(showLoader || showParseSpinner) &&
        <div className="LoaderBackDrop">
        </div>
      }
      {(showLoader || showParseSpinner) &&
        <div className="Loader">
          <Spinner className="spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      <ToastContainer
        autoClose={1000}
        position="top-center"
      />
    </React.Fragment>
  );
};
export default CreateTemplate;
