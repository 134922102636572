import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"
import Col from "react-bootstrap/Col"

import { updateAnimationBasedOnValue } from "../../animations"
import moment from 'moment'
import "./index.css"

const PreviewModal = (props) => {
    let { pageData, show, handleClose, updatePageData } = props;
    let fontMap = {
        "OpenSans-Regular": "Open Sans"
    }
    const [pTag, setPTag] = useState(null);
    const [pValue, setPValue] = useState(null);
    const [pValueIndex, setPValueIndex] = useState(null);
    const [reducedPercentageValue, setReducedPercentageValue] = useState(null);
    const [canvasWidthValue, setCanvasWidthValue] = useState(null);

    const trim = (str) => {
        return str.replace(/^\s+|\s+$/gm, '');
    }

    const measureText = (pText, pFontSize, pStyle) => {
        var lDiv = document.createElement('div');

        document.body.appendChild(lDiv);

        if (pStyle != null) {
            lDiv.style = pStyle;
        }
        lDiv.style.fontSize = "" + pFontSize + "px";
        lDiv.style.position = "absolute";
        lDiv.style.height = "auto";
        lDiv.style.width = "auto";

        lDiv.innerHTML = pText;

        var lResult = {
            width: lDiv.offsetWidth,
            height: lDiv.clientHeight
        };

        document.body.removeChild(lDiv);
        lDiv = null;

        return lResult;
    }

    const rgbaToHex = (rgba) => {
        var inParts = rgba.substring(rgba.indexOf("(")).split(","),
            r = parseInt(trim(inParts[0].substring(1)), 10),
            g = parseInt(trim(inParts[1]), 10),
            b = parseInt(trim(inParts[2]), 10),
            a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
        var outParts = [
            r.toString(16),
            g.toString(16),
            b.toString(16),
            Math.round(a * 255).toString(16).substring(0, 2)
        ];

        outParts.forEach(function (part, i) {
            if (part.length === 1) {
                outParts[i] = '0' + part;
            }
        })

        return ('#' + outParts.join(''));
    }

    const getFontData = (fontData) => {
        let splitFont = fontData.split("-")
        let fontName = splitFont[0];
        let fontWeight = splitFont[1];
        fontName = fontName.replace(/([A-Z])/g, ' $1')
        fontName = fontName.trim()
        return {
            fontName,
            fontWeight
        }
    }

    const getCoordinate = (parent, value, reducedPercentage) => {
        return (value) * (reducedPercentage)
    }
    const getReducedPercentage = (canvasWidth) => {
        let width;
        width = 414;
        const reduceValue = (width * 1) / canvasWidth;
        setReducedPercentageValue(reduceValue)
        return reduceValue;
        // setReducedPercentageValue(1)
        // return 1;
    }

    const typeWriter = () => {
        let speed = 50;
        let para = document.getElementsByClassName("typeWriter");
        if (para) {
            let txt = para.innerHTML;
            para.innerHTML = ""
            if (txt) {
                for (let i = 0; i < txt.length; i++) {
                    let charAt = txt.charAt(i);
                    setTimeout(function () {
                        para.innerHTML += charAt;
                        i++;
                        //your code to be executed after 1 second
                    }, speed);
                }
            }
        }
    }

    const renderPage = () => {
        let mainDiv = document.getElementById("mycanvas");
        let sampleJson = JSON.parse(JSON.stringify(pageData));
        let dataToUpdate = JSON.parse(JSON.stringify(pageData));
        if (pageData && mainDiv) {
            mainDiv.innerHTML = "";
            let sampleValues = sampleJson.defaultValues.texts;
            let reducePercentage = getReducedPercentage(sampleJson.canvasDetails.width);
            let canvasWidth = sampleJson.canvasDetails.width * reducePercentage
            setCanvasWidthValue(canvasWidth)
            let canvasHeight = sampleJson.canvasDetails.height * reducePercentage

            mainDiv.setAttribute("style", "width:" + canvasWidth + "px; " + "height:" + canvasHeight + "px")
            mainDiv.style.backgroundImage = `url(${pageData.backgroundImage.imagePath})`;
            mainDiv.style.backgroundPosition = 'center'
            mainDiv.style.backgroundSize = 'cover'

            for (let i = 0; i < sampleJson.texts.length; i++) {
                let text = sampleJson.texts[i];
                let style = text.textDetails.style;
                let fontFamily = style.font.name;

                let para = document.createElement("div");
                para.classList.add("boxStyle")
                para.onclick = (e) => {
                    para.classList.add("boxStyleInActive");
                    setPTag(e);
                    setPValue(text);
                    setPValueIndex(i)
                }
                let transXX = text.textDetails.transform[0];
                let transXY = text.textDetails.transform[1];
                let transYX = text.textDetails.transform[2];
                let transYY = text.textDetails.transform[3];
                let transTX = text.textDetails.transform[4];
                let transTY = text.textDetails.transform[5];

                let valueToRender;
                if (text.type == "address") {
                    valueToRender = sampleValues[i].textValue;
                } else if (text.type == "date") {
                    let date = moment(sampleJson.dateMaps[sampleValues[i].key]).format(sampleValues[i].format);
                    valueToRender = date;
                } else {
                    valueToRender = sampleValues[i];
                }
                if (style.fontCaps > 0) {
                    valueToRender = valueToRender.toUpperCase();
                }
                para.innerHTML = valueToRender;
                para.style.position = "absolute";
                para.style.whiteSpace = "nowrap";

                if (text.animationDelay && text.animationType) {
                    updateAnimationBasedOnValue(text.animationType, para, text.animationDelay);
                }
                // console.log("style.fontSize", style.fontSize)
                // console.log("reducePercentage", reducePercentage)
                // console.log("transYY", transYY)
                if (transYY <= 0) {
                    transYY = 1
                }
                let fontSize = (style.fontSize * reducePercentage) * transYY;
                // console.log("fontSize", fontSize)
                let top = getCoordinate(canvasHeight, text.top, reducePercentage);
                let left = getCoordinate(canvasWidth, text.left, reducePercentage);
                let bottom = getCoordinate(canvasWidth, text.bottom, reducePercentage);
                let right = getCoordinate(canvasWidth, text.right, reducePercentage);
                para.style.fontFamily = fontFamily;
                para.style.cursor = "grab"
                para.style.marginTop = "0px";
                para.style.marginBottom = "0px";

                para.style.textAlign = "center";
                para.style.verticalAlign = 'baseline'

                para.style.color = rgbaToHex("rgba(" + style.fillColor.r + "," + style.fillColor.g + "," + style.fillColor.b + "," + style.fillColor.a + ")");

                text.defaultValue = text.defaultValue.replace(/\n/g, "<br>");

                if (text.defaultValue.includes("<br>") || text.defaultValue.includes("\\n")) {
                    if (!style.autoLeading && style.autoLeading != null) {
                        let lineHeight = Math.round((style.leading * transYY)) * reducePercentage;
                        let deduction = 0;
                        if (lineHeight > 25) {
                            deduction = 9
                        }
                        para.style.lineHeight = lineHeight - deduction + "px";
                    } else {
                        para.style.lineHeight = 1.2;
                    }
                } else {
                    para.style.lineHeight = 1.2;
                }
                if (style.tracking) {
                    para.style.letterSpacing = fontSize * style.tracking / 1000
                }
                para.style.fontSize = fontSize + "px";
                para.style.top = -1000 + "px";
                para.style.left = -1000 + "px";

                mainDiv.appendChild(para);

                let currentWidth = para.offsetWidth;

                if (!text.mainWidth) {
                    dataToUpdate.texts[i].mainWidth = currentWidth / reducePercentage;
                    // console.log("valueToRender", valueToRender)
                    // console.log("currentWidth", currentWidth)
                    // console.log("reducePercentage", reducePercentage)
                    updatePageData(dataToUpdate, "1")
                    text.mainWidth = currentWidth / reducePercentage;
                }

                left = left + ((text.mainWidth * reducePercentage) - (currentWidth)) / 2;
                para.style.top = top + "px";
                para.style.left = left + "px";

                if (text.valueUpdated) {
                    text.valueUpdated = false;
                    dataToUpdate.texts[i].mainWidth = currentWidth / reducePercentage
                    dataToUpdate.texts[i].left = left / reducePercentage
                    dataToUpdate.texts[i].valueUpdated = false
                    updatePageData(dataToUpdate, "2")
                    text.mainWidth = currentWidth / reducePercentage;
                }

            }
        }
    }

    useEffect(() => {
        renderPage();
    }, [props]);
    return (
        <Modal
            show={show} onHide={handleClose}
            dialogClassName="modal-414w"
        >
            <Modal.Header closeButton>
                <Col>
                    <Modal.Title>Page Preview</Modal.Title>
                </Col>
                <Col>
                    <Button
                        size="sm"
                        onClick={e => {
                            if (pTag.target) {
                                let currentWidthValue = pTag.target.offsetWidth;
                                let newValue = parseFloat((canvasWidthValue - currentWidthValue) / 2)
                                pTag.target.style.left = newValue + "px";
                                pValue.left = newValue / reducedPercentageValue;
                                let currentWidth = currentWidthValue;
                                pValue.mainWidth = currentWidth / reducedPercentageValue;
                                let dataToUpdate = JSON.parse(JSON.stringify(pageData));
                                dataToUpdate.texts[pValueIndex].mainWidth = pValue.mainWidth
                                dataToUpdate.texts[pValueIndex].left = newValue / reducedPercentageValue
                                updatePageData(dataToUpdate, "3")
                            }
                        }}
                    >
                        Center Align
            </Button>
                    <Button
                        size="sm"
                        onClick={e => {
                            if (pTag.target) {
                                let newValue = parseFloat(pTag.target.style.top, 10) - 1;
                                pTag.target.style.top = newValue + "px";
                                pValue.top = newValue / reducedPercentageValue;
                                let dataToUpdate = JSON.parse(JSON.stringify(pageData));
                                dataToUpdate.texts[pValueIndex].top = newValue / reducedPercentageValue
                                updatePageData(dataToUpdate, "4")
                            }
                        }}
                    >
                        Up
            </Button>
                    <Button
                        style={{ marginLeft: 2 }}
                        size="sm"
                        onClick={e => {
                            if (pTag.target) {
                                let newValue = parseFloat(pTag.target.style.top, 10) + 1;
                                pTag.target.style.top = newValue + 1 + "px";
                                pValue.top = newValue / reducedPercentageValue;
                                let dataToUpdate = JSON.parse(JSON.stringify(pageData));
                                dataToUpdate.texts[pValueIndex].top = newValue / reducedPercentageValue
                                updatePageData(dataToUpdate, "5")
                            }
                        }}
                    >
                        Down
            </Button>
                    <Button
                        style={{ marginLeft: 2 }}
                        size="sm"
                        onClick={e => {
                            if (pTag.target) {
                                let newValue = parseFloat(pTag.target.style.left, 10) - 1;
                                pTag.target.style.left = newValue + "px";
                                pValue.left = newValue / reducedPercentageValue;
                                let currentWidth = pTag.target.offsetWidth;
                                pValue.mainWidth = currentWidth / reducedPercentageValue;
                                let dataToUpdate = JSON.parse(JSON.stringify(pageData));
                                dataToUpdate.texts[pValueIndex].mainWidth = pValue.mainWidth
                                dataToUpdate.texts[pValueIndex].left = newValue / reducedPercentageValue
                                updatePageData(dataToUpdate, "6")
                            }
                        }}
                    >
                        Left
            </Button>
                    <Button
                        style={{ marginLeft: 2 }}
                        size="sm"
                        onClick={e => {
                            if (pTag.target) {
                                let newValue = parseFloat(pTag.target.style.left, 10) + 1;
                                pTag.target.style.left = parseFloat(pTag.target.style.left, 10) + 1 + "px";
                                pValue.left = newValue / reducedPercentageValue;
                                let currentWidth = pTag.target.offsetWidth;
                                pValue.mainWidth = currentWidth  / reducedPercentageValue;
                                let dataToUpdate = JSON.parse(JSON.stringify(pageData));
                                dataToUpdate.texts[pValueIndex].mainWidth = pValue.mainWidth
                                dataToUpdate.texts[pValueIndex].left = newValue / reducedPercentageValue
                                updatePageData(dataToUpdate, "7")
                            }
                        }}
                    >
                        Right
            </Button>
                </Col>
            </Modal.Header>
            <Modal.Body>
                <div class="canvasCarrier">
                    <div id="mycanvas"></div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
          </Button>
            </Modal.Footer>
        </Modal >)
}
export default PreviewModal;