module.exports.getBaseUrl = () => {
    if (process.env.REACT_APP_ENV == "PREProduction") {
        return "https://pre-prod.wedmegood.com/einvite-node/";
    } else if (process.env.REACT_APP_ENV == "Development") {
        return "https://developmentwow.wedmegood.com/einvite-node/";
    } else if (process.env.REACT_APP_ENV == "Production") {
        return "https://wedmegood.com/einvite-node/";
    } else {
        //return "http://localhost:8081/einvite-node/";
        return "https://developmentwow.wedmegood.com/einvite-node/";
    }

}