import { routeConstant, routeName } from "./constants";
import Wrapper from "../pages/Wrapper";
import CreateTemplate from "../pages/CreateTemplate";
import AddFont from "../pages/AddFont";
import TemplateList from "../pages/TemplateList";
import NotFound from "../pages/NotFound";
import AddVideoInvite from "../pages/VideoTemplate";

const commonRoutes = [
  {
    path: routeConstant.TEMPLATE_LIST,
    component: TemplateList,
    exact: true,
    name: routeName.TEMPLATE_LIST_PAGE,
    errorElement: <NotFound />
  },
  {
    path: routeConstant.CREATE_TEMPLATE,
    component: CreateTemplate,
    exact: true,
    name: routeName.CREATE_TEMPLATE_PAGE,
  },
  {
    path: routeConstant.ADD_FONT,
    component: AddFont,
    exact: true,
    name: routeName.ADD_FONT_PAGE,
  },
  {
    path: routeConstant.ADD_VIDEO_INVITE,
    component: AddVideoInvite,
    exact: true,
    name: routeName.ADD_VIDEO_INVITE_PAGE,
  },
];

export const returnRoute = () => {
  let allRoutes = [...commonRoutes];
  const notFoundRound = [
    {
      component: NotFound,
      name: routeName.NOT_FOUND,
    },
  ];

  
  const route = [
    {
      component: Wrapper,
      routes: [...allRoutes, ...notFoundRound],
    },
  ];
  return allRoutes;
};
