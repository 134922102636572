import React from "react";
import "./style.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { returnRoute } from "./routes";
import "./css/base.css"
import "bootstrap/dist/css/bootstrap.min.css";


const router = createBrowserRouter(returnRoute().map((routes) => {
  return {
    path: routes.path,
    element: <routes.component key={routes.name} />,
    name: routes.name
  }
}));

const App = () => {
  return (
    <div>
      <link href="https://fonts.googleapis.com/css?family=Open Sans" rel="stylesheet"></link>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;

