import React from "react";
import notFoundImage from "../../assets/images/notFound.png";

import "./css/index.css";
const NotFound = () => {
  return (
    <div className="notFoundContainer">
      <div className="imageContainer">
        <img
          src={notFoundImage}
          alt="notFoundImage"
          className="notFoundImage"
        />
      </div>
    </div>
  );
};

export default NotFound;
