import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";

import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import Table from "react-bootstrap/Table"
import Modal from "react-bootstrap/Modal"
import Popover from "react-bootstrap/Popover"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import ProgressBar from "react-bootstrap/ProgressBar"

import axios from "axios";
import { getUrlParameter } from "../../helper";
import { useNavigate } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from "react-js-pagination";
import { getBaseUrl } from "../../config";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import ReactImageZoom from "react-image-zoom";

import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ToastContainer, toast } from "react-toastify";
import { data } from "jquery";
import { resizeFile } from "../../helper/index";
import moment from "moment";


const videoInvitesCDN = 'https://videoinvites.wedmegood.com/';
const AddVideoInvite = () => {
  const [timestampPage, setTimestampPage] = useState(false);
  const [timestamps, setTimestamps] = useState([{}]);
  const [videoTitle, setVideoTitle] = useState("");
  const [designedBy, setDesignedBy] = useState("");
  const [designerEmail, setDesignerEmail] = useState("");
  const [price, setPrice] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [slug, setSlug] = useState("");

  const [templateThumbnail, setTemplateThumbnail] = useState("");
  const [thumbnailFile, setThumbnailFile] = useState();
  const [thumbnailFileName, setThumbnailFileName] = useState("");
  const [textOrder, setTextOrder] = useState({
    currIndex: null,
    newIndex: null,
    status: false,
  });

  const [filePath, setFilePath] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [fileDuration, setFileDuration] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showParseSpinner, setShowParseSpinner] = useState(false);

  const [invitationId, setInvitationId] = useState(null);
  const [invitationTemplateId, setInvitationTemplateId] = useState(null);

  const [pages, setPages] = useState([]);
  const [showPages, setshowPages] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const [inputType, setInputType] = useState(1);
  const [characterLimit, setCharacterLimit] = useState();
  const [label, setLabel] = useState();
  const [placeholder, setPlaceholder] = useState();
  const [isDisabled, setIsDisabled] = useState(0);
  const [isMandatory, setIsMandatory] = useState(1);
  const [showAddPageModel, setShowAddPageModel] = useState(false);
  const [showPageReorderModel, setShowPageReorderModel] = useState(false);

  const handleCloseAddPageModel = () => {
    setShowAddPageModel(false);
    setUsingImage(false);
    setUsingTimestamp(false);
  };
  const handleShowAddPageModel = () => setShowAddPageModel(true);
  const handleClosePageReorderModel = () => setShowPageReorderModel(false);
  const handleShowPageReorderModel = () => setShowPageReorderModel(true);

  const [showEditInviteModel, setShowEditInviteModel] = useState(false);
  const [usingTimestamp, setUsingTimestamp] = useState(false);
  const [usingImage, setUsingImage] = useState(false);
  const handleCloseEditInviteModel = () => setShowEditInviteModel(false);
  const handleShowEditInviteModel = () => setShowEditInviteModel(true);
  const [progress, setProgress] = useState(0);
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [videoPath, setVideoPath] = useState();
  const [pageNewIndex, setPageNewIndex] = useState(null);
  const [pageIdArray, setPageIdArray] = useState([]);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const templateId = getUrlParameter("templateId");

  let timestampArray = [];

  const saveThumbnailFile = (e) => {
    setThumbnailFile(e.target.files[0]);
    setThumbnailFileName(e.target.files[0].name);
    toast.success(`${e.target.files[0].name} file added`);
  };
  //open home page
  const navigate = useNavigate();
  const openMainScreen = () => {
    navigate("/");
  };

  //to handle page  change
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  //to get the page data
  const getPageData = (id) => {
    let template_id;
    if (id) {
      template_id = id;
    } else template_id = pages[activePage - 1].id;

    if (pages.length === 0 && template_id !== null) {
      axios
        .get(`${getBaseUrl()}api/invitationTemplates/${template_id}`)

        .then((response) => {
          setPages(response.data.invitationPages);
          pages.map((data, index) => pageIdArray.push(data.id));
          setPageIdArray((prev) => [...prev]);
          setVideoTitle(response.data.title);
          setDesignedBy(response.data.designedBy);
          setDesignerEmail(response.data.designer_email);
          setPrice(response.data.price);
          setDiscountedPrice(response.data.discounted_price);
          setTemplateThumbnail(response.data.thumbnail);
          setSlug(response.data.slug);
          setInvitationId(response.data.invitationId);
          setInvitationTemplateId(response.data.templateId);
          setFilePath(response.data.video_url);
          setFileDuration(response.data.video_duration);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  //call when Add text button clicked
  const onHandleAddTextData = () => {
    let msg = "";
    if (
      !(
        characterLimit == "" ||
        characterLimit == null ||
        characterLimit == undefined
      )
    ) {
      msg = `Max ${characterLimit} characters`;
    }
    let dataObj = {
      id: pages[activePage - 1].data.texts.length + 1,
      type: inputType,
      value: "",
      placeholder: placeholder,
      isDisabled: isDisabled,
      isMandatory: isMandatory,
      label: label,
      characterLimit: characterLimit,
      characterLimitMsg: msg,
    };
    pages[activePage - 1].data.texts.push(dataObj);
    setPages((prev) => [...prev]);
    toast.success("Text Added in the list");
    //once you add the text in table reset every input box
    setInputType(1);
    setCharacterLimit("");
    setPlaceholder("");
    setIsDisabled(0);
    setIsMandatory(1);
    setLabel("");
  };

  useEffect(() => {
    if (showPages || templateId) {
      getPageData(templateId);
    }
  }, [activePage, showPages, templateId]);

  useEffect(() => {
    //render page
  }, [timestamps, pages, inputType, templateThumbnail, videoPath]);

  const onTextChangeHandle = (value, textIndex) => {
    const currentValue = pages[activePage - 1].data.texts[textIndex].value
    pages[activePage - 1].data.texts[textIndex].placeholder = value;
    pages[activePage - 1].data.texts[textIndex].value = currentValue ? value : "";
    setPages((prev) => [...prev]);
  };

  const onPrefilledChange = (textIndex) => {
    const currentValue = pages[activePage - 1].data.texts[textIndex].value
    pages[activePage - 1].data.texts[textIndex].value = currentValue ? "": pages[activePage - 1].data.texts[textIndex].placeholder;
    setPages((prev) => [...prev]);
  };

  const textInput = (Data, textIndex) => {
    console.log(Data)
    return (
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label size="sm">
          <b>Placeholder </b>
          <Form.Check
            type={"checkbox"}
            id={`checkbox-${Data.id}`}
            label={`Should be prefilled`}
            checked={Data.value ? true: false}
            onChange={(e) => {
              onPrefilledChange(textIndex)
            }}
          />
        </Form.Label>
        <Form.Control
          type="text"
          rows={5}
          value={Data.placeholder.replaceAll("<br>", "\n")}
          onChange={(e) => {
            onTextChangeHandle(e.target.value, textIndex);
          }}
          size="sm"
        />
      </Form.Group>
    );
  };
  const textAreaInput = (Data, textIndex) => {
    return (
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label size="sm">
          Placeholder
          <Form.Check
            type={"checkbox"}
            id={`checkbox-${Data.id}`}
            label={`Should be prefilled`}
            checked={Data.value ? true: false}
            onChange={(e) => {
              onPrefilledChange(textIndex)
            }}
          />
        </Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={5}
          value={Data.placeholder.replaceAll("<br>", "\n")}
          onChange={(e) => {
            onTextChangeHandle(e.target.value, textIndex);
          }}
          size="sm"
        />
      </Form.Group>
    );
  };
  const dateInput = (Data, textIndex) => {
    return (
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label size="sm">Placeholder</Form.Label>
        <Form.Control
          type="text"
          rows={5}
          value={Data.placeholder.replaceAll("<br>", "\n")}
          onChange={(e) => {
            onTextChangeHandle(e.target.value, textIndex);
          }}
          size="sm"
        />
      </Form.Group>
    );
  };

  const textPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Types</Popover.Header>
      <Popover.Body>
        <h6>Text Type</h6>
        Text fields are basic text fields and can be given any value.
        <br></br>
        <h6 style={{ marginTop: "2px" }}>TextArea Type</h6>
        Text Area are also like text fields the only difference that you get
        bigger space.
        <h6 style={{ marginTop: "2px" }}>Address Type</h6>
        Address Fields are also like text fields the only difference that you
        get bigger space.
        <h6 style={{ marginTop: "2px" }}>Date Type</h6>
        On Date fields you are required to enter the date and time like
        weddingDate and time etc.
      </Popover.Body>
    </Popover>
  );

  const textInfoButton = () => (
    <OverlayTrigger trigger="click" placement="left" overlay={textPopover}>
      <Button className="buttonWidth" variant="success">
        More Info
      </Button>
    </OverlayTrigger>
  );

  const deleteTextButton = (textIndex) => (
    <Button
      className="buttonWidth"
      variant="danger"
      onClick={() => {
        pages[activePage - 1].data.texts.splice(textIndex, 1);
        setPages((pages) => [...pages]);
      }}
    >
      Delete
    </Button>
  );

  const reorderTextButton = () => (
    <Button
      className="buttonWidth"
      variant="success"
      onClick={() => {
        pages[activePage - 1].data.texts = moveArrayItemToNewIndex(
          pages[activePage - 1].data.texts,
          textOrder.currIndex,
          textOrder.newIndex
        );

        setPages((prev) => [...prev]);
      }}
    >
      Reorder
    </Button>
  );
  const onTypeChangeHandle = (value, textIndex) => {
    pages[activePage - 1].data.texts[textIndex].type = value;
    setPages((prev) => [...prev]);
  };

  const renderInputBasedOnType = (Data, textIndex) => {
    if (Data.type == 1) return textInput(Data, textIndex);
    else if (Data.type == 2) {
      return textAreaInput(Data, textIndex);
    } else if (Data.type == 3) {
      return textAreaInput(Data, textIndex);
    } else if (Data.type == 4) {
      return dateInput(Data, textIndex);
    }
  };
  //page 1
  const renderVideoInviteForm = () => {
    return (
      <Container>
        <Row>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Video Title</Form.Label>
              <Form.Control
                type="text"
                value={videoTitle}
                onChange={(e) => {
                  setVideoTitle(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Category</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value="Video Cards"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Card Type</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                value="Video"
                disabled
              ></Form.Control>
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Video Slug</Form.Label>
              <Form.Control
                type="text"
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Designed By</Form.Label>
              <Form.Control
                type="text"
                value={designedBy}
                onChange={(e) => {
                  setDesignedBy(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Designer's Email</Form.Label>
              <Form.Control
                type="text"
                value={designerEmail}
                onChange={(e) => {
                  setDesignerEmail(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Price</Form.Label>
              <Form.Control
                type="number"
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={3}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Discounted Price</Form.Label>
              <Form.Control
                type="number"
                value={discountedPrice}
                onChange={(e) => {
                  setDiscountedPrice(e.target.value);
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <Col>
                <Form size="sm">
                  <Form.Label size="sm">Upload Thumbnail</Form.Label>
                  <Form.Control
                    type="file"
                    id="custom-file"
                    label={
                      thumbnailFileName
                        ? thumbnailFileName
                        : "Upload Image Here"
                    }
                    size="sm"
                    custom
                    onChange={saveThumbnailFile}
                  />
                </Form>
              </Col>
              {thumbnailFileName && (
                <Col className="align-self-end">
                  <Button
                    className="btn btn-primary mb-3"
                    onClick={(e) => {
                      uploadThumbnail();
                    }}
                  >
                    Upload
                  </Button>
                </Col>
              )}
            </Row>
            {templateThumbnail && (
              <Col xs={12} className="p-0">
                <img src={templateThumbnail} width="350" controls></img>
              </Col>
            )}
          </Col>

          <Col>
            <Row>
              <Col>
                <Form className="align-self-end">
                  <Form.Label size="sm">Upload Video</Form.Label>
                  <Form.Control
                    type="file"
                    id="custom-file"
                    label={fileName ? fileName : "ADD VIDEO FILE"}
                    size="sm"
                    custom
                    onChange={saveFile}
                  />
                </Form>
              </Col>
              {fileName && (
                <Col className="align-self-end">
                  <Button
                    className="btn btn-primary mb-3"
                    onClick={() => {
                      uploadVideoOnAws(file, fileName);
                    }}
                    disabled={uploadingStatus}
                  >
                    {uploadingStatus ? "Uploading..." : "Upload Video"}
                  </Button>
                </Col>
              )}
            </Row>
            <Col xs={12} className="mb-3 align-self-center p-0">
              {uploadingStatus && (
                <ProgressBar
                  animated={progress === 100 && !uploadingStatus ? false : true}
                  variant={"success"}
                  now={progress}
                  label={`File Upload ${progress}%`}
                />
              )}
            </Col>
            {videoPath && (
              <Col xs={12} className="p-0">
                <video width="350" controls>
                  <source src={videoPath} type="video/mp4" />
                </video>
              </Col>
            )}
          </Col>
        </Row>
        <br></br>
        <Row>
          <Col xs={4}>
            <Button
              style={{ marginBottom: 20 }}
              onClick={() => {
                if (checkValidation(true)) {
                  addNewVideoInvite();
                  setTimestampPage(!timestampPage);
                }
              }}
            >
              Create Video Invite
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };
  //page 2
  const renderAddTimeStampPage = () => {
    return (
      <Container>
        <Row>
          {filePath && (
            <Col xs={4}>
              <video width="750" height="500" controls>
                <source src={filePath} type="video/mp4" />
              </video>
            </Col>
          )}
        </Row>
        <br></br>
        <Row style={{ marginLeft: 0 }}>
          <Col xs={2}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Minutes</Form.Label>
              <Form.Control
                type="number"
                value={minutes}
                onChange={(e) => {
                  if (e.target.value >= 0 && e.target.value <= 60)
                    setMinutes(parseInt(e.target.value));
                }}
                size="sm"
              />
            </Form.Group>
          </Col>

          <Col xs={2}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label size="sm">Seconds</Form.Label>
              <Form.Control
                type="number"
                value={seconds}
                onChange={(e) => {
                  if (e.target.value >= 0 && e.target.value <= 60)
                    setSeconds(parseInt(e.target.value));
                }}
                size="sm"
              />
            </Form.Group>
          </Col>
          <Col xs={4} className="align-self-end">
            <Button
              className="btn btn-primary mb-3"
              onClick={() => {
                timeStampValidation(true);
              }}
            >
              Add
            </Button>
          </Col>
        </Row>
        {timestamps.length > 0 && (
          <Container>
            <Row>
              <Col xs={4}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Timestamp</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timestamps.length > 0 &&
                      timestamps &&
                      timestamps.map((data, index) => {
                        if (data.timeStamp)
                          return (
                            <tr>
                              <td>{data.timeStamp}</td>
                              <td>
                                <TrashFill
                                  color="red"
                                  onClick={() => {
                                    setTimestamps(
                                      timestamps.filter(
                                        (item, key) => key !== index
                                      )
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                      })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        )}

        <Row>
          <Col xs={4}>
            <Button
              style={{ marginBottom: 20 }}
              onClick={() => {
                addTimestamps();
                setTimestampPage(!timestampPage);
              }}
            >
              Create Template
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };
  const renderPageElement = (pageData, data, type, textIndex) => {
    return (
      <Form>
        <Row className="box" style={{ marginTop: "10px" }}>
          <Col>{renderInputBasedOnType(data, textIndex)}</Col>
          <Col>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Label size="sm">Type</Form.Label>
                <Form.Select
                  // as="select"
                  value={data.type}
                  onChange={(e) => {
                    onTypeChangeHandle(parseInt(e.target.value), textIndex);
                  }}
                >
                  <option value="1">Text</option>
                  <option value="2">TextArea</option>
                  <option value="3">Address</option>
                  <option value="4">Date</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label size="sm">Label</Form.Label>
                <Form.Control
                  type="text"
                  value={data.label}
                  onChange={(e) => {
                    pages[activePage - 1].data.texts[textIndex].label =
                      e.target.value;
                    setPages((prev) => [...prev]);
                  }}
                />
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label size="sm">Character Limit</Form.Label>
                <Form.Control
                  type="number"
                  value={data.characterLimit}
                  onChange={(e) => {
                    pages[activePage - 1].data.texts[textIndex].characterLimit =
                      e.target.value;

                    let msg = "";
                    if (
                      !(
                        e.target.value == "" ||
                        e.target.value == null ||
                        e.target.value == undefined
                      )
                    ) {
                      msg = `Max ${e.target.value} characters`;
                    }

                    pages[activePage - 1].data.texts[
                      textIndex
                    ].characterLimitMsg = msg;
                    setPages((prev) => [...prev]);
                  }}
                />
              </Form.Group>
            </Row>
            <div
              style={{
                color: "green",
                marginTop: "-15px",
                marginBottom: "10px",
                marginLeft: "-15px",
              }}
            >
              {data.characterLimitMsg}
            </div>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label size="sm">Text Order</Form.Label>
                <Form.Control
                  type="number"
                  value={data.id}
                  onChange={(e) => {
                    if (
                      parseInt(e.target.value) >
                        pages[activePage - 1].data.texts.length ||
                      parseInt(e.target.value) <= 0
                    ) {
                      toast.error("Enter a valid number");
                    } else {
                      pages[activePage - 1].data.texts[textIndex].id = parseInt(
                        e.target.value
                      );
                      setPages((prev) => [...prev]);
                      setTextOrder({
                        currIndex: textIndex,
                        newIndex: e.target.value - 1,
                      });
                    }
                  }}
                />
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Label size="sm">Is Mandatory</Form.Label>
                <Form.Select
                  // as="select"
                  value={data.isMandatory}
                  onChange={(e) => {
                    pages[activePage - 1].data.texts[textIndex].isMandatory =
                      parseInt(e.target.value);
                    setPages((prev) => [...prev]);
                  }}
                  size="sm"
                >
                  <option value="1">yes</option>
                  <option value="0">no</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ flex: 1 }}>
              <Form.Group
                className="increasedWidth"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Label size="sm">Is Disabled</Form.Label>
                <Form.Select
                  // as="select"
                  value={data.isDisabled}
                  onChange={(e) => {
                    pages[activePage - 1].data.texts[textIndex].isDisabled =
                      parseInt(e.target.value);
                    setPages((prev) => [...prev]);
                  }}
                  size="sm"
                >
                  <option value="0">enable</option>
                  <option value="1">disable</option>
                </Form.Select>
              </Form.Group>
            </Row>
          </Col>
          <Col xs={2}>
            <Row>{textInfoButton()}</Row>
            <br></br>
            <Row>{reorderTextButton()}</Row>
            <br></br>
            <Row>{deleteTextButton(textIndex)}</Row>
          </Col>
        </Row>
      </Form>
    );
  };

  //to show pages
  const renderPage = (page, index) => {
    if (activePage == index + 1) {
      return (
        <Container
          id={`page_${index}`}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "-15px",
          }}
        >
          <Row>
            <Col xs={2}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => {
                  addPageData();
                }}
              >
                Save Page Data
              </Button>
            </Col>

            <Col xs={2}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => {
                  handleShowAddPageModel();
                }}
              >
                Add New Page
              </Button>
              <Modal show={showAddPageModel} onHide={handleCloseAddPageModel}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col>
                        <Button
                          style={{ marginBottom: 20, marginTop: 20 }}
                          onClick={() => {
                            setUsingTimestamp(true);
                            setUsingImage(false);
                          }}
                        >
                          Via TimeStamps
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{ marginBottom: 20, marginTop: 20 }}
                          onClick={() => {
                            setUsingImage(true);
                            setUsingTimestamp(false);
                          }}
                        >
                          Via Image
                        </Button>
                      </Col>
                    </Row>
                    {usingTimestamp && (
                      <Row>
                        <Col xs={12}>
                          <video width="250" height="200" controls>
                            <source src={filePath} type="video/mp4" />
                          </video>
                        </Col>
                        <Col>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label size="sm">Minutes</Form.Label>
                            <Form.Control
                              type="number"
                              value={minutes}
                              onChange={(e) => {
                                if (e.target.value >= 0 && e.target.value <= 60)
                                  setMinutes(parseInt(e.target.value));
                              }}
                              size="sm"
                            />
                          </Form.Group>
                        </Col>

                        <Col>
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label size="sm">Seconds</Form.Label>
                            <Form.Control
                              type="number"
                              value={seconds}
                              onChange={(e) => {
                                if (e.target.value >= 0 && e.target.value <= 60)
                                  setSeconds(parseInt(e.target.value));
                              }}
                              size="sm"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    {usingImage && (
                      <Row>
                        <Col xs={9}>
                          <Form size="sm">
                            <Form.Control
                              type="file"
                              id="custom-file"
                              label={
                                thumbnailFileName
                                  ? thumbnailFileName
                                  : "Upload Image Here"
                              }
                              size="sm"
                              custom
                              onChange={saveThumbnailFile}
                            />
                          </Form>
                        </Col>
                        {thumbnailFileName && (
                          <Col>
                            <Button
                              size="sm"
                              onClick={(e) => {
                                uploadThumbnail();
                              }}
                            >
                              Upload
                            </Button>
                          </Col>
                        )}
                      </Row>
                    )}
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseAddPageModel}>
                    Close
                  </Button>
                  {(usingTimestamp || usingImage) && (
                    <Button
                      variant="primary"
                      onClick={() => {
                        timeStampValidation(false);
                      }}
                    >
                      Add Page
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>
            </Col>

            <Col xs={2}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => {
                  handleShowEditInviteModel();
                }}
              >
                Edit Video Invite
              </Button>

              <Modal
                show={showEditInviteModel}
                onHide={handleCloseEditInviteModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Video Invite</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Video Title</Form.Label>
                          <Form.Control
                            type="text"
                            value={videoTitle}
                            onChange={(e) => {
                              setVideoTitle(e.target.value);
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Category</Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            value="Video Cards"
                            disabled
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label>Card Type</Form.Label>
                          <Form.Control
                            type="text"
                            size="sm"
                            value="Video"
                            disabled
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Designed By</Form.Label>
                          <Form.Control
                            type="text"
                            value={designedBy}
                            onChange={(e) => {
                              setDesignedBy(e.target.value);
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Designer's Email</Form.Label>
                          <Form.Control
                            type="text"
                            value={designerEmail}
                            onChange={(e) => {
                              setDesignerEmail(e.target.value);
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Price</Form.Label>
                          <Form.Control
                            type="number"
                            value={price}
                            onChange={(e) => {
                              setPrice(e.target.value);
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Discounted Price</Form.Label>
                          <Form.Control
                            type="number"
                            value={discountedPrice}
                            onChange={(e) => {
                              setDiscountedPrice(e.target.value);
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form size="sm">
                          <Form.Control
                            type="file"
                            id="custom-file"
                            label={
                              thumbnailFileName
                                ? thumbnailFileName
                                : "Upload Image Here"
                            }
                            size="sm"
                            custom
                            onChange={saveThumbnailFile}
                          />
                        </Form>
                      </Col>
                      <Col>
                        <Button size="sm" onClick={uploadThumbnail}>
                          Upload
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      {templateThumbnail && (
                        <Col>
                          <img
                            src={templateThumbnail}
                            height="200"
                            width="200"
                          ></img>
                        </Col>
                      )}
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={handleCloseEditInviteModel}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (checkValidation(false)) {
                        editVideoInvite();
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>

            <Col xs={2}>
              <Button
                style={{ marginBottom: 20 }}
                onClick={() => {
                  handleShowPageReorderModel();
                }}
              >
                Page Reorder
              </Button>
              <Modal
                show={showPageReorderModel}
                onHide={handleClosePageReorderModel}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Page</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label size="sm">Enter Page Index</Form.Label>
                          <Form.Control
                            type="number"
                            value={pageNewIndex}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) > pages.length ||
                                parseInt(e.target.value) <= 0
                              ) {
                                toast.error("enter a valid number");
                              } else setPageNewIndex(parseInt(e.target.value));
                            }}
                            size="sm"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      handleClosePageReorderModel();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      pageReorder();
                      handleClosePageReorderModel();
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
            <Col xs={2}>
              <Button
                variant="danger"
                style={{ marginBottom: 20 }}
                onClick={() => {
                  removePage();
                }}
              >
                Remove Page
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs={6}>
              <Row>
                <Col xs={4}>
                  <ReactImageZoom
                    zoomPosition="original"
                    width="320"
                    zoomWidth="320"
                    img={page.data.backgroundImage}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col>
                  <Form size="sm">
                    <Form.Control
                      type="file"
                      id="custom-file"
                      label={
                        thumbnailFileName
                          ? thumbnailFileName
                          : "Upload Image Here"
                      }
                      size="sm"
                      custom
                      onChange={saveThumbnailFile}
                    />
                  </Form>
                </Col>
                <Col xs={6}>
                  <Button
                    size="sm"
                    width="20px"
                    onClick={() => {
                      uploadThumbnail(true);
                    }}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <Row>
                <Col xs={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      // as="select"
                      size="sm"
                      value={inputType}
                      onChange={(e) => {
                        setInputType(parseInt(e.target.value));
                      }}
                    >
                      <option value="1">text</option>
                      <option value="2">textarea</option>
                      <option value="3">address</option>
                      <option value="4">date</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label size="sm">Label</Form.Label>
                    <Form.Control
                      type="text"
                      value={label}
                      onChange={(e) => {
                        setLabel(e.target.value);
                      }}
                      size="sm"
                    />
                  </Form.Group>
                </Col>

                {inputType == 1 && (
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label size="sm">Placeholder</Form.Label>
                      <Form.Control
                        type="text"
                        value={placeholder}
                        onChange={(e) => {
                          setPlaceholder(e.target.value);
                        }}
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                )}

                {(inputType == 2 || inputType == 3) && (
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label size="sm">Placeholder</Form.Label>
                      <Form.Control
                        as="textarea"
                        type="text"
                        value={placeholder}
                        onChange={(e) => {
                          setPlaceholder(e.target.value);
                        }}
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                )}

                {inputType == 4 && (
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label size="sm">Placeholder</Form.Label>
                      <Form.Control
                        type="text"
                        value={placeholder}
                        onChange={(e) => {
                          setPlaceholder(e.target.value);
                        }}
                        size="sm"
                      />
                    </Form.Group>
                  </Col>
                )}

                <Col xs={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label size="sm">Character Limit</Form.Label>
                    <Form.Control
                      type="number"
                      value={characterLimit}
                      onChange={(e) => {
                        setCharacterLimit(e.target.value);
                      }}
                      size="sm"
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label size="sm">Is Mandatory</Form.Label>
                    <Form.Select
                      // as="select"
                      value={isMandatory}
                      onChange={(e) => {
                        setIsMandatory(parseInt(e.target.value));
                      }}
                      size="sm"
                    >
                      <option value="1">yes</option>
                      <option value="0">no</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label size="sm">Is Disabled</Form.Label>
                    <Form.Select
                      // as="select"
                      value={isDisabled}
                      onChange={(e) => {
                        setIsDisabled(parseInt(e.target.value));
                      }}
                      size="sm"
                    >
                      <option value="0">enable</option>
                      <option value="1">disable</option>
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col xs={6} className="align-self-end">
                  <Button
                    className="btn btn-primary mb-3"
                    onClick={() => {
                      if (
                        placeholder === "" ||
                        placeholder === undefined ||
                        placeholder === undefined
                      ) {
                        toast.error("Placeholder is required.");
                      } else onHandleAddTextData();
                    }}
                  >
                    Add Text Data
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              {pages.length > 0 &&
                pages &&
                pages[activePage - 1].data.texts.map((data, textIndex) => {
                  return renderPageElement(
                    page[activePage - 1],
                    data,
                    "Text",
                    textIndex
                  );
                })}
            </Col>
          </Row>
        </Container>
      );
    }
  };
  //to save video file
  const saveFile = (e) => {
    if (!/^video/.test(e.target.files[0].type)) {
      toast.error("Upload a video file");
      setIsVideo(false);
    } else {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        let result = new Date(video.duration * 1000)
          .toISOString()
          .slice(14, 19);
        setFileDuration(result);
      };
      video.src = URL.createObjectURL(e.target.files[0]);
      setFilePath(null);
    }
  };

  // video upload on s3 bucket
  AWS.config.update({
    accessKeyId: "AKIAY5M4OP2JZJA3E52S",
    secretAccessKey: "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",
  });

  const myBucket = new AWS.S3({
    region: "ap-south-1",
  });

  const uploadVideoOnAws = async (selectFile, selectFileName) => {
    if (selectFile) {
      setUploadingStatus(true);
      const params = {
        ACL: "public-read",
        ContentType: selectFile.type,
        Body: selectFile,
        Bucket: "e-invite-data",
        Key: new Date().getTime() + selectFileName,
      };

      await myBucket
        .upload(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err, data) => {
          if (err) {
            console.log(err);
            setUploadingStatus(false);
          }
          if (data) {
            if(data?.Location?.split('amazonaws.com/')[1]){
              setVideoPath(videoInvitesCDN + data?.Location?.split('amazonaws.com/')[1]);
            } else {
              setVideoPath(data.Location);
            }
            setIsVideo(true);
            setUploadingStatus(false);
          }
        });
    } else {
      toast.error("Upload a video file");
      setIsVideo(false);
    }
  };

  async function sendData(url, data) {
    const formData = new FormData();
    formData.append("file", data);
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    return response;
  }

  const uploadThumbnail = async (editImage) => {
    let resizedFile = await resizeFile(thumbnailFile);
    setShowParseSpinner(true);
    let response = await sendData(
      `${getBaseUrl()}api/v1/file/uploadFile?type=image`,
      resizedFile
    );
    try {
      response = await response.json();
      setShowParseSpinner(false);
      let imagePath = response.data.data.Location;
      setTemplateThumbnail(imagePath);
      toast.success("Thumbnail Uploaded Successfully");

      if (editImage && imagePath) {
        pages[activePage - 1].data.backgroundImage = imagePath;
        setPages((prev) => [...prev]);
      }
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to upload thumbnail, try again");
    }
  };

  const checkValidation = (videoValidationRequired) => {
    if (videoTitle === "" || videoTitle === undefined) {
      toast.error("Video Title is empty.");
      return false;
    }
    if (slug === "" || slug === undefined) {
      toast.error("Slug is empty.");
      return false;
    }
    if (designedBy === "" || designedBy === undefined) {
      toast.error("Designed By is empty.");
      return false;
    }
    if (
      designedBy === "" ||
      designedBy === undefined ||
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(designerEmail)
    ) {
      toast.error("Enter a valid email.");
      return false;
    }
    if (price === undefined || price === "" || price === 0 || price === null) {
      toast.error("Price is empty.");
      return false;
    }
    if (
      discountedPrice !== undefined &&
      Number(discountedPrice) >= Number(price)
    ) {
      toast.error("Discounted Price cant be greater than the Actual Price");
      return false;
    }

    if (!isVideo && videoValidationRequired) {
      toast.error("Upload a video file");
      return false;
    }
    return true;
  };
  const timeStampValidation = (createTemplatePage) => {
    let min = minutes;
    let sec = seconds;
    if (minutes === undefined || isNaN(minutes)) min = 0;
    if (seconds === undefined || isNaN(seconds)) sec = 0;

    let totalSeconds = min * 60 + sec;

    let timeInTimestampFormat = new Date(totalSeconds * 1000)
      .toISOString()
      .slice(14, 19);
    let PagesTimeStamp = [];
    if (createTemplatePage)
      PagesTimeStamp = timestamps.map((data) => data.timeStamp);
    else PagesTimeStamp = pages.map((data) => data.data.timeStamp);
    if (usingTimestamp || createTemplatePage) {
      if (
        fileDuration < timeInTimestampFormat ||
        timeInTimestampFormat === undefined
      )
        toast.error("please enter valid timestamp for image");
      else {
        if (
          PagesTimeStamp.filter((data) => data == timeInTimestampFormat)
            .length > 0
        ) {
          if (timeInTimestampFormat === undefined)
            toast.error("Enter a valid TimeStamp");
          else toast.error("Timestamp already exists");
        } else {
          if (createTemplatePage) addNewTimeStamp(timeInTimestampFormat);
          else {
            timestampArray.push(timeInTimestampFormat);
            addNewPage(usingTimestamp);
            handleCloseAddPageModel();
          }
        }
      }
    } else {
      if (thumbnailFileName) {
        addNewPage(usingTimestamp);
        handleCloseAddPageModel();
      } else toast.error("Please enter an Image File");
    }
  };
  //to upload timestamp on s3
  const addNewTimeStamp = async (timeStamp) => {
    setShowParseSpinner(true);

    let data = {
      video_url: videoPath,
      timeStamp: timeStamp,
    };

    let response = await fetch(
      `${getBaseUrl()}api/v1/videoInvite/upload-screenshot`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    try {
      response = await response.json();

      if (Object.keys(timestamps[0]).length === 0)
        setTimestamps(() => [
          { timeStamp: response.timeStamp, image_url: response.image_url },
        ]);
      else
        setTimestamps((timestamps) =>
          [
            ...timestamps,
            { timeStamp: response.timeStamp, image_url: response.image_url },
          ].sort(function (x, y) {
            if (x.timeStamp > y.timeStamp) {
              return 1;
            }
            if (y.timeStamp > x.timeStamp) {
              return -1;
            }
            return 0;
          })
        );
      setShowParseSpinner(false);
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to create screenshot for this timestamp, try again");
    }
  };
  //to add new video invitation
  const addNewVideoInvite = async (index) => {
    setShowParseSpinner(true);
    let data = {
      title: videoTitle,
      slug: slug,
      category_id: 3,
      cardType: "video",
      designedBy: designedBy,
      video_duration: fileDuration,
      price: price,
      discounted_price: discountedPrice,
      designer_email: designerEmail,
      video_url: videoPath,
    };
    let response = await fetch(
      `${getBaseUrl()}api/v1/videoInvite/video-upload`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    try {
      response = await response.json();
      setShowParseSpinner(false);
      setFilePath(response.data.video_url);
      setInvitationId(response.data.id);
      setThumbnailFileName("");
      setTemplateThumbnail(response.data.thumbnail);
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to create new video invite, try again");
    }
  };
  // to create template
  const addTimestamps = async (index) => {
    setShowParseSpinner(true);
    let data = {
      title: videoTitle,
      slug: slug,
      invitation_id: invitationId,
      video_url: filePath,
      image_urls: Object.keys(timestamps[0]).length === 0 ? [] : timestamps,
    };
    let response = await fetch(
      `${getBaseUrl()}api/v1/videoInvite/create-template`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    try {
      response = await response.json();
      setInvitationTemplateId(response.invitation_template_id);
      if (response.pages.length === 0) {
        openMainScreen();
      } else {
        setPages(response.pages);
        setShowParseSpinner(false);
        setshowPages(true);
      }
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to create template, try again");
    }
  };
  //to update page data
  const addPageData = async () => {
    setShowParseSpinner(true);
    let page_id;
    if (templateId) {
      page_id = pages[activePage - 1].pageId;
    } else page_id = pages[activePage - 1].id;
    let data1 = pages[activePage - 1].data;
    let finaldata = {
      data: data1,
    };
    finaldata.data.texts.map((data, index) => {
      data.id = index + 1;
    });

    let response = await fetch(
      `${getBaseUrl()}api/v1/videoInvite/update-page/${page_id} `,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finaldata),
      }
    );

    try {
      response = await response.json();
      setShowParseSpinner(false);
      toast.success("Data Added Successfully");
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to Add Page Data, try again");
    }
  };
  //to edit video invite attributes
  const editVideoInvite = async () => {
    setShowParseSpinner(true);
    let finaldata = {
      title: videoTitle,
      designedBy: designedBy,
      designer_email: designerEmail,
      thumbnail: templateThumbnail,
      price: price,
      discounted_price: discountedPrice,
    };
    let response = await fetch(
      `${getBaseUrl()}api/invitations/${invitationId} `,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finaldata),
      }
    );

    try {
      response = await response.json();
      setShowParseSpinner(false);
      handleCloseEditInviteModel();
      toast.success("Data Added Successfully");
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to Edit Video Invite, try again");
    }
  };
  const pageReorder = async () => {
    let arr = pages;
    let pageOrderArray = [];
    let new_index = pageNewIndex - 1;
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(activePage - 1, 1)[0]);
    arr.map((data, index) => {
      pageOrderArray.push({
        pageId: data.id ? data.id : data.pageId,
        order: index + 1,
      });
    });

    let finaldata = {
      invitation_id: invitationId,
      pageOrder: pageOrderArray,
    };
    let response = await fetch(
      `${getBaseUrl()}api/invitationPages/reorderPages `,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finaldata),
      }
    );

    try {
      if (response.status === 200) {
        response = await response.json();
        setShowParseSpinner(false);
        toast.success("Page Reorder Successfully");
      } else toast.error("Unable to reorder the page, try again");
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to reorder the page, try again");
    }
  };
  const addNewPage = async (using_timestamp) => {
    setShowParseSpinner(true);
    let image = "";
    let time_stamp = "";
    let set_using_timestamp = 1;
    if (using_timestamp) {
      time_stamp = timestampArray[timestampArray.length - 1];
    } else {
      set_using_timestamp = 0;
      image = templateThumbnail;
    }
    let pageNumber = pages.length + 1;

    let finaldata = {
      invitation_template_id: invitationTemplateId,
      data: {
        backgroundImage: image,
        pageOrder: pageNumber,
        texts: [],
        address: [],
        dates: [],
      },
      using_timeStamp: set_using_timestamp,
      time_stamp: time_stamp,
      video_url: filePath,
    };
    let response = await fetch(
      `${getBaseUrl()}api/invitationPages/addNewPage `,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finaldata),
      }
    );
    try {
      response = await response.json();
      setShowParseSpinner(false);
      pages.push(response.data);
      setPages((prev) => [...prev]);
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Unable to Add New Page, try again");
    }
  };
  const removePage = async () => {
    let newPageArray = pages.filter((data, index) => index !== activePage - 1);
    newPageArray.map((data, index) => {
      data.order = index + 1;
      data.data.pageOrder = index + 1;
      data.pageNumber = index + 1;
    });
    let finaldata = {
      templateId: invitationTemplateId,
      title: videoTitle,
      categoryId: 3,
      invitationId: invitationId,
      designedBy: designedBy,
      invitationPages: newPageArray,
      thumbnail: templateThumbnail,
      slug: slug,
      price: price,
      video_url: filePath,
      discounted_price: discountedPrice,
      designer_email: designerEmail,
      video_duration: fileDuration,
      cardType: "video",
    };

    let response = await fetch(
      `${getBaseUrl()}api/invitationTemplates/updateVideoInviteTemplate/${invitationTemplateId} `,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(finaldata),
      }
    );
    try {
      response = await response.json();
      setShowParseSpinner(false);
      if (response == 1) {
        setPages(newPageArray);
        setActivePage(1);
        toast.success("Page Removed Successfully");
      } else toast.error("Fail to remove , try again");
    } catch (e) {
      setShowParseSpinner(false);
      toast.error("Fail to remove , try again");
    }
  };
  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    arr.map((data, index) => {
      data.id = index + 1;
    });

    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    arr.map((data, index) => {
      data.id = index + 1;
    });

    return arr;
  };

  return (
    <React.Fragment>
      <Container>
        <br></br>
        <Row>
          <Col>
            <h1>
              <b>Video Invite</b>
            </h1>
          </Col>
          <Col className="align-self-end">
            <Button
              className="btn btn-success btn-lg float-right"
              style={{ marginBottom: 20 }}
              onClick={() => {
                openMainScreen();
              }}
            >
              Back To Home Page
            </Button>
          </Col>
        </Row>
      </Container>
      {!timestampPage && !showPages && !templateId && renderVideoInviteForm()}
      {timestampPage && filePath && !templateId && renderAddTimeStampPage()}

      {(showPages || templateId) && (
        <Container>
          <Col>
            <Row className="mt-2" style={{ marginTop: "20px" }}>
              {pages.length > 0 && (
                <Pagination
                  itemClass="page-item"
                  linkClass="page-link"
                  activePage={activePage}
                  itemsCountPerPage={1}
                  totalItemsCount={pages.length}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                />
              )}
            </Row>

            <Row>
              {pages &&
                pages.length > 0 &&
                pages.map((page, index) => {
                  return renderPage(page, index);
                })}
            </Row>
            <Row>
              {pages.length === 0 && (
                <Col xs={2}>
                  <Button
                    style={{ marginBottom: 20 }}
                    onClick={() => {
                      handleShowAddPageModel();
                    }}
                  >
                    Add New Page
                  </Button>
                  <Modal
                    show={showAddPageModel}
                    onHide={handleCloseAddPageModel}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Add Page</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Container>
                        <Row>
                          <Col>
                            <Button
                              style={{ marginBottom: 20, marginTop: 20 }}
                              onClick={() => {
                                setUsingTimestamp(true);
                                setUsingImage(false);
                              }}
                            >
                              Via TimeStamps
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              style={{ marginBottom: 20, marginTop: 20 }}
                              onClick={() => {
                                setUsingImage(true);
                                setUsingTimestamp(false);
                              }}
                            >
                              Via Image
                            </Button>
                          </Col>
                        </Row>
                        {usingTimestamp && (
                          <Row>
                            <Col xs={12}>
                              <video width="250" height="200" controls>
                                <source src={filePath} type="video/mp4" />
                              </video>
                            </Col>
                            <Col>
                              <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label size="sm">Minutes</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={minutes}
                                  onChange={(e) => {
                                    if (
                                      e.target.value >= 0 &&
                                      e.target.value <= 60
                                    )
                                      setMinutes(parseInt(e.target.value));
                                  }}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Label size="sm">Seconds</Form.Label>
                                <Form.Control
                                  type="number"
                                  value={seconds}
                                  onChange={(e) => {
                                    if (
                                      e.target.value >= 0 &&
                                      e.target.value <= 60
                                    )
                                      setSeconds(parseInt(e.target.value));
                                  }}
                                  size="sm"
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        {usingImage && (
                          <Row>
                            <Col xs={9}>
                              <Form size="sm">
                                <Form.Control
                                  type="file"
                                  id="custom-file"
                                  label={
                                    thumbnailFileName
                                      ? thumbnailFileName
                                      : "Upload Image Here"
                                  }
                                  size="sm"
                                  custom
                                  onChange={saveThumbnailFile}
                                />
                              </Form>
                            </Col>
                            {thumbnailFileName && (
                              <Col>
                                <Button
                                  size="sm"
                                  onClick={(e) => {
                                    uploadThumbnail();
                                  }}
                                >
                                  Upload
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )}
                      </Container>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={handleCloseAddPageModel}
                      >
                        Close
                      </Button>
                      {(usingTimestamp || usingImage) && (
                        <Button
                          variant="primary"
                          onClick={() => {
                            timeStampValidation(false);
                          }}
                        >
                          Add Page
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>
                </Col>
              )}
            </Row>
          </Col>
        </Container>
      )}

      {(showLoader || showParseSpinner) && (
        <div className="LoaderBackDrop"></div>
      )}
      {(showLoader || showParseSpinner) && (
        <div className="Loader">
          <Spinner className="spinner" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <ToastContainer autoClose={3000} position="top-center" />
    </React.Fragment>
  );
};
export default AddVideoInvite;
