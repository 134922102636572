export const routeConstant = {
  TEMPLATE_LIST: "/",
  CREATE_TEMPLATE: "/create-template",
  ADD_FONT: "/add-font",
  ADD_VIDEO_INVITE: "/add-video-invite",
};

export const routeName = {
  TEMPLATE_LIST_PAGE: "templateListPage",
  CREATE_TEMPLATE_PAGE: "createTemplatePage",
  ADD_FONT_PAGE: "addFontPage",
  ADD_VIDEO_INVITE_PAGE: "addVideoInvitePage",
  NOT_FOUND: "notFound",
};
