const fadeIn = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.opacity = 0;
    para.style.animation = "3s ease fadeInAnimation " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}

const popIn = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.opacity = 0;
    para.style.animation = "pop-in " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}
const circle = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.clipPath = "circle(0%)";
    para.style.animation = "circle 2s " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}

const inset = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.clipPath = "circle(0%)";
    para.style.animation = "circle 2s " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}

const leftToRight = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.transform = "translateX(-150%)"; 
    para.style.transform = "scale(0)"; 
    para.style.animation = "left-to-right-slide 2s " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}

const RightToLeft = (para, delay) => {
    para.style.whiteSpace = "nowrap";
    para.style.transform = "translateX(150%)"; 
    para.style.transform = "scale(0)"; 
    para.style.animation = "right-to-left-slide 2s " + (parseInt(delay) / 1000) + 's ';
    para.style.animationIterationCount = 1;
    para.style.animationFillMode = "forwards";
}

const typeWriter = (para, delay) => {
    para.classList.add("typeWriter");
}


const updateAnimationBasedOnValue = (animationType, para, delay) => {
    switch (animationType) {
        case 'fadeIn':
            return fadeIn(para, delay)
        case 'popIn':
            return popIn(para, delay)
        case 'circle':
            return circle(para, delay)
        case 'inset':
            return inset(para, delay)
        case 'leftToRight':
            return leftToRight(para, delay)
        case 'RightToLeft':
            return RightToLeft(para, delay)
        default:
            return null
    }
}
module.exports = {
    updateAnimationBasedOnValue
}