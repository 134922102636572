require("ag-psd/initialize-canvas.js");
const { readPsd, initializeCanvas } = require("ag-psd");
const { resizeFile, getCroppedImg } = require("../helper")
const { getBaseUrl } = require("../config/index")
async function sendData(url, data) {
  const formData = new FormData();
  formData.append("file", data);
  const response = await fetch(url, {
    method: "POST",
    body: formData
  });
  return response;
}

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });
}
function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}
export default async buffer => {
  try {
    const psd2 = readPsd(buffer);
    let parsedPSDJson = {
      pageOrder: 1,
      canvasDetails: {
        width: psd2.width,
        height: psd2.height
      },
      texts: [],
      backgroundImage: {},
      defaultValues: {
        texts: [],
        address: []
      }
    };
    for (let i = 0; i < psd2.children.length; i++) {
      let child = psd2.children[i];
      if (!child.hidden) {
        if (!child.text) {
          // console.log("psd2.width", psd2.width)
          // console.log("psd2.height", psd2.height)
          // let crop = {
          //   unit: 'px', // default, can be 'px' or '%'
          //   x: child.top * -1,
          //   y: child.left * -1,
          //   width: 1000,
          //   height: 1000
          // }

          // let blob = await getCroppedImg(child.canvas, crop, "bgImage.jpeg")
          // console.log("blob", blob)
          let blob = await getCanvasBlob(child.canvas);

          let file = blobToFile(blob, "bgImage.jpeg");
          file = await resizeFile(file);
          let response = await sendData(
            `${getBaseUrl()}api/v1/file/uploadFile?type=image`,
            file
          );
          response = await response.json();
          let imagePath = response.data.data.Location;

          parsedPSDJson.backgroundImage = {
            imagePath: imagePath,
            position: {
              top: child.top,
              left: child.left,
              bottom: child.bottom,
              right: child.right
            }
          };
        } else {
          if (child.text.text) {
            let textValue = child.text.text.replace(/(\r\n|\r|\n)/g, "<br>");
            // console.log("textValue", textValue)
            // console.log("child", child)
            // console.log("child.text.style.fontSize", child.text.style.fontSize)

            if (child.text.style.fontCaps > 0) {
              textValue = textValue.toUpperCase();
            }
            if (!child.text.style.font) {
              if (child.text.styleRuns) {
                child.text.style.font = child.text.styleRuns[0]
              }
            }
            if (!child.text.style.fillColor) {
              if (child.text.styleRuns) {
                child.text.style.fillColor = child.text.styleRuns[0].style.fillColor
              }
            }


            if (!child.text.style.fontSize) {
              if (child.text.style.font && child.text.style.font.style) {
                child.text.style.fontSize = child.text.style.font.style.fontSize
              }
              if (!child.text.style.fontSize) {
                if (child.text.styleRuns) {
                  child.text.style.fontSize = child.text.styleRuns[0].style.fontSize
                }
              }
            }
            if (child.effects) {
              if (child.effects.solidFill && child.effects.solidFill.enabled && child.effects.solidFill.color) {
                child.text.style.fillColor.r = child.effects.solidFill.color.r
                child.text.style.fillColor.g = child.effects.solidFill.color.g
                child.text.style.fillColor.b = child.effects.solidFill.color.b
              }
            }
            if (!child.text.style.fillColor) {
              child.text.style.fillColor = {}
              child.text.style.fillColor.r = 0
              child.text.style.fillColor.g = 0
              child.text.style.fillColor.b = 0
            }
            if (!child.text.style.fontSize) {
              child.text.style.fontSize = 20
            }
            let text = {
              top: child.top,
              left: child.left,
              bottom: child.bottom,
              right: child.right,
              defaultValue: textValue,
              animation: null,
              effects: child.effects,
              textDetails: child.text,
              type: "text"
            };
            parsedPSDJson.defaultValues.texts.push(textValue);
            parsedPSDJson.texts.push(text);
          } else {
            return false
          }
        }
      }
    }
    return parsedPSDJson;
  } catch (e) {
    console.log(e)
    return false
  }
};