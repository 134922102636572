import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import Table from "react-bootstrap/Table"

import axios from 'axios';
import noimage from "../../assets/images/noimage.png";
import { getBaseUrl } from "../../config"
import { TrashFill } from 'react-bootstrap-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';

const AddFont = () => {
    const [fontList, setFontList] = useState(null);
    const [fontTitle, setFontTitle] = useState("");
    const [filePath, setFilePath] = useState("");
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showParseSpinner, setShowParseSpinner] = useState(false);

    const saveFile = e => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setFilePath(null)
    };
    const submit = (fontName, id) => {
        confirmAlert({
            title: 'Confirmation',
            message: `Are you sure you want to delete ${fontName}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteFont(id)
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }
    const getFontList = () => {
        axios.get(`${getBaseUrl()}api/fonts`)
            .then(resp => {
                setFontList(resp.data)
            })
            .catch(err => {
                console.error(err);
            });
    }
    async function sendData(url, data) {
        const formData = new FormData();
        formData.append("file", data);
        const response = await fetch(url, {
            method: "POST",
            body: formData
        });
        return response;
    }
    const addNewFont = () => {
        if (!fontTitle || !filePath) {
            if(!fontTitle){
                toast.error("Font title is missing")
            }
            if(!filePath){
                toast.error("Please upload font first")
            }
        } else {
            let body = {
                key: fontTitle,
                url: filePath
            }
            let requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(body)
            };
            fetch(
                `${getBaseUrl()}api/fonts`,
                requestOptions
            )
                .then(response => response.json())
                .then(data => {
                    getFontList();
                });
        }
    }
    const deleteFont = (id) => {
        let requestOptions = {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
        };
        fetch(
            `${getBaseUrl()}api/fonts/${id}`,
            requestOptions
        )
            .then(response => response.json())
            .then(data => {
                getFontList();
            });
    }
    const uploadFont = async index => {
        setShowParseSpinner(true);
        let response = await sendData(
            `${getBaseUrl()}api/v1/file/uploadFile`,
            file
        );
        try {
            response = await response.json();
            setShowParseSpinner(false)
            let filePath = response.data.data.Location;
            setFilePath(filePath);
        } catch (e) {
            setShowParseSpinner(false)
            toast.error("Unable to upload thumbnail, try again")
        }
    };

    useEffect(() => {
        getFontList();
    }, []);

    return (
        <React.Fragment>
            <Container>
                <h1>Fonts</h1>
            </Container>
            <Container>
                <Row>
                    <Col xs={4}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label size="sm">Font Title</Form.Label>
                            <Form.Control
                                type="text"
                                value={fontTitle}
                                onChange={e => {
                                    setFontTitle(e.target.value);
                                }}
                                size="sm"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <Form>
                            <Form.Control
                                type="file"
                                id="custom-file"
                                label={fileName ? fileName : "ADD FONT FILE"}
                                size="sm"
                                custom
                                onChange={saveFile}
                            />
                        </Form>
                    </Col>

                    <Col xs={4}>
                        <Button
                            style={{ marginTop: 0 }}
                            size="sm"
                            onClick={uploadFont}>
                            Upload
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>

                        <Button style={{ marginBottom: 20 }}
                            onClick={() => {
                                addNewFont()
                            }}
                        >
                            Add New Font
              </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Font Name</th>
                            <th>Font Url</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fontList && fontList.map((data, index) => {
                            return (<tr>
                                <td>{data.key}</td>
                                <td><a href={data.url}>{data.url}</a></td>
                                <td><TrashFill color="red" onClick={() => { submit(data.key, data.id) }} /></td>
                            </tr>)
                        })}

                    </tbody>
                </Table>
            </Container>
            {(showLoader || showParseSpinner) &&
                <div className="LoaderBackDrop">
                </div>
            }
            {(showLoader || showParseSpinner) &&
                <div className="Loader">
                    <Spinner className="spinner" animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>}
            <ToastContainer
                autoClose={1000}
                position="top-center"
            />
        </React.Fragment >
    )
};
export default AddFont;
